.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 18px;
  transition: 0.4s;
  display: flex;
  justify-content: space-between;
}

.active,
.accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 18px;
  background-color: white;
  overflow: hidden;
}
