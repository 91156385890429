:root {
  --color-primary: #68b43a;
  --color-primary-light: #7fc954;
  --color-primary-dark: #4e8c2a;
  --color-secondary: #138496;
  --color-background: #f5fff0;
  --color-text: #333;
  --color-text-light: #666;
  --color-white: #fff;
  --color-gray-100: #f8f9fa;
  --color-gray-200: #e9ecef;
  --color-gray-300: #dee2e6;
  --color-gray-400: #ced4da;
  --color-gray-500: #adb5bd;
  --color-success: #28a745;
  --color-danger: #dc3545;
  --border-radius-sm: 4px;
  --border-radius: 8px;
  --border-radius-lg: 16px;
  --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  --box-shadow-hover: 0 4px 12px rgba(0, 0, 0, 0.12);
  --transition: 0.2s ease-in-out;
}

.indicacoes {
  min-height: 100vh;
  background: linear-gradient(150deg, var(--color-background) 0%, #cfd5aa 100%);
}

.container {
  max-width: 1248px;
  margin: 0 auto;
  padding: clamp(1rem, 5vw, 2rem);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: clamp(1.5rem, 4vw, 2rem);
  flex-wrap: wrap;
  gap: 1rem;
}

.title {
  color: #223a13;
  font-size: clamp(1.5rem, 5vw, 1.875rem);
}

.actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.periodSelector {
  position: relative;
}

.periodSelector select {
  appearance: none;
  background: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: var(--border-radius);
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  font-size: 1rem;
  color: var(--color-text);
  cursor: pointer;
  transition: border-color var(--transition), box-shadow var(--transition);
}

.periodSelector::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
  color: var(--color-primary);
}

.periodSelector select:hover {
  border-color: var(--color-primary);
}

.periodSelector select:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.2);
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color var(--transition), color var(--transition), box-shadow var(--transition);
}

.refreshButton {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.refreshButton:hover {
  background-color: var(--color-primary-dark);
}

.copyButton {
  background-color: #17a2b8;
  color: var(--color-white);
}

.copyButton:hover {
  background-color: #138496;
}

.actionButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.2);
}

.actionButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.icon {
  font-size: 1.25rem;
}

.referralContainer {
  position: relative;
}

.referralTooltip {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 1rem;
  margin-top: 0.5rem;
  z-index: 10;
  width: max-content;
  max-width: 300px;
  animation: fadeIn 0.2s ease-out;
}

.referralTooltip p {
  margin: 0;
  font-size: 0.875rem;
  color: var(--color-text);
}

.content {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  padding: clamp(1rem, 3vw, 2rem);
  box-shadow: var(--box-shadow);
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  font-size: clamp(1rem, 2vw, 1.125rem);
  color: var(--color-text-light);
}

.error {
  color: var(--color-danger);
}

.tableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table th,
.table td {
  padding: clamp(0.75rem, 2vw, 1rem);
  text-align: left;
  border-bottom: 1px solid var(--color-gray-200);
}

.table th {
  background-color: var(--color-gray-100);
  font-weight: 600;
  color: var(--color-text-light);
  position: sticky;
  top: 0;
  z-index: 10;
}

.table tr:last-child td {
  border-bottom: none;
}

.user {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.userImage,
.userInitials {
  width: clamp(32px, 8vw, 40px);
  height: clamp(32px, 8vw, 40px);
  border-radius: 50%;
  object-fit: cover;
}

.userInitials {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
  color: var(--color-white);
  font-weight: 600;
  font-size: clamp(0.75rem, 2vw, 1rem);
}

.userName {
  font-weight: 500;
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.rank {
  font-weight: 600;
  color: var(--color-text);
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.status {
  padding: 0.25rem 0.5rem;
  border-radius: var(--border-radius-sm);
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
  font-weight: 600;
  text-transform: uppercase;
}

.statusativo {
  background-color: var(--color-success);
  color: var(--color-white);
}

.statusinativo {
  background-color: var(--color-danger);
  color: var(--color-white);
}

.emptyState {
  text-align: center;
  padding: clamp(2rem, 5vw, 3rem);
  background-color: var(--color-gray-100);
  border-radius: var(--border-radius);
}

.emptyStateIcon {
  width: clamp(48px, 10vw, 64px);
  height: clamp(48px, 10vw, 64px);
  margin: 0 auto 1rem;
  color: var(--color-primary);
  font-size: clamp(2rem, 5vw, 3rem);
}

.emptyStateTitle {
  font-size: clamp(1.25rem, 3vw, 1.5rem);
  color: var(--color-secondary);
  margin-bottom: 0.5rem;
}

.emptyStateDescription {
  color: var(--color-text-light);
  margin-bottom: 1.5rem;
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.emptyStateButton {
  padding: 0.75rem 1.5rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: var(--border-radius);
  font-size: clamp(0.875rem, 2vw, 1rem);
  cursor: pointer;
  transition: background-color var(--transition);
}

.emptyStateButton:hover {
  background-color: var(--color-primary-dark);
}

@media (max-width: 768px) {
  .actions {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .periodSelector,
  .actionButton {
    width: 100%;
  }

  .table {
    display: block;
  }

  .table thead {
    display: none;
  }

  .table tbody,
  .table tr,
  .table td {
    display: block;
  }

  .table tr {
    margin-bottom: 1rem;
    border: 1px solid var(--color-gray-300);
    border-radius: var(--border-radius);
    padding: 1rem;
  }

  .table td {
    border-bottom: none;
    padding: 0.5rem 0;
  }

  .table td::before {
    content: attr(data-label);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--color-text-light);
    display: block;
    margin-bottom: 0.25rem;
  }

  .user {
    flex-direction: row;
    align-items: center;
  }

  .rank {
    display: inline-block;
    margin-left: 0.5rem;
  }
}

@media (hover: hover) {
  .table tr:hover {
    background-color: var(--color-gray-100);
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.content {
  animation: fadeIn 0.3s ease-out;
}

.table tr {
  animation: fadeIn 0.3s ease-out;
  animation-fill-mode: both;
}

.table tr:nth-child(1) { animation-delay: 0.05s; }
.table tr:nth-child(2) { animation-delay: 0.1s; }
.table tr:nth-child(3) { animation-delay: 0.15s; }
.table tr:nth-child(4) { animation-delay: 0.2s; }
.table tr:nth-child(5) { animation-delay: 0.25s; }

.actionButton:focus,
.periodSelector select:focus,
.emptyStateButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.4);
}

.tableContainer::-webkit-scrollbar {
  height: 8px;
}

.tableContainer::-webkit-scrollbar-track {
  background: var(--color-gray-100);
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-400);
  border-radius: 20px;
}

.tableContainer::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-gray-500);
}