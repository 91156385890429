.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-painel-home {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  background: linear-gradient(
    150deg,
    rgba(245, 255, 240, 1) 0%,
    rgba(207, 213, 170, 1) 100%
  );
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
  background-color: var(--dl-color-gradient-primary);
}
.home-frame319 {
  gap: 40px;
  display: flex;
  max-width: 1248px;
  width: 100%;
  margin: 0 auto;
  padding: 32px 24px;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.home-text {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 32px;
  font-size: 30px;
  color: #223a13;
  font-weight: 600;
}
.home-estatisticas-text04 span,
.home-estatisticas-text16 span,
.home-estatisticas-text28 span {
  font-weight: 700;
}
.home-estatisticas-text08 strong,
.home-estatisticas-text20 strong {
  color: #888;
}
.home-estatisticas-text span,
.home-estatisticas-text12 span,
.home-estatisticas-text24 span,
.home-estatisticas-text36,
.home-estatisticas-text44 span {
  font-weight: 600;
  color:rgba(44, 76, 24, 1);
}
.home-estatisticas-text48 {
  font-weight: 700;
  color: #888;
}
.home-frame321 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
}
@media (max-width: 991px) {
  .home-frame321 {
    flex-direction: column;
  }
}

.dropbtn {
  border: 1px solid #c0c1c2 !important;
}

.month-dropdown {
  align-self: flex-end;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 40px;
  user-select: none;
}

.dropdown-header {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 10px 16px;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746);
  width: 100%;
}

.dropdown-header span {
  color: rgba(44, 76, 24, 1);
  font-weight: 600;
  margin-left: 8px;
  font-size: 16px;
  transition: color 0.3s ease;
}

.icon-label {
  flex: 1;
  display:flex;
  align-items:center;
  justify-content: center;
}

.nav-icon, .icon-label svg {
  cursor: pointer;
  fill: rgba(44, 76, 24, 1);
  transition: color 0.3s ease;
}

.nav-icon[disabled] {
  cursor: not-allowed;
  fill: rgba(44, 76, 24, 0.3);
}

.nav-icon:hover:not([disabled]) {
  color: #68b43a;
}

.dropdown-icon {
  transition: transform 0.3s ease;
}

.dropdown-icon.open {
  transform: rotate(180deg);
}

.ranking__points {
  display: flex;
  align-items: center;
}

.dropdown-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  padding: 16px;
  position: absolute;
  top: calc(100% + 8px);
  width: 100%;
  background: #fff;
  border-radius: 8px;
  z-index: 10;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: fadeInDown 0.3s ease forwards;
}

.dropdown-grid-item {
  padding: 12px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.dropdown-grid-item:hover {
  background: rgba(44, 76, 24, 1);
  color: #fff;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .month-dropdown {
    width: 100%;
    margin-top: 24px;
  }

  .dropdown-header span {
    font-size: 14px;
  }

  .dropdown-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.home-estatisticas-enter {
  animation: slideLeft 0.3s forwards;
}

.home-estatisticas-exit {
  animation: slideRight 0.3s forwards;
}

@media screen and (max-width: 546px) {
  .dropdown-grid {
    grid-template-columns: repeat(1,1fr);
  }
  .dropdown-grid-item, .ranking__index, .ranking__points {
    font-size: 14px;
  }
  .home-text {
    font-size: 24px;
  }

  .home-frame319 {
    gap: 24px;
  }

  .ranking__filter {
    font-size:14px;
    padding: 8px;
  }

  .ranking__content {
    gap: 16px;
  }

  .ranking__frame {
    width: 70px;
  }

  .ranking__acronym {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }

  .ranking__name {
    font-size: 14px;
    line-height:1.5;
  }

  .ranking__top-three {
    gap: 12px;
    padding: 16px 8px;
  }

  .ranking__acronym--small {
    font-size: 16px;
  }

  .ranking__acronym--small {
    width: 32px;
    height: 32px;
  }

  .ranking__list-content {
    padding: 12px 8px;
  }

  .points-gif {
    height: 18px;
    width: 18px;
  }

  .month-dropdown {
    margin-bottom: -8px;
  }

  .HeaderH3-18Medium {
    font-size: 16px;
  }

  .TextSmall-14Regular {
    font-size: 12px;
  }

  .home-estatisticas-frame374, .home-estatisticas-frame372, .home-estatisticas-frame373, .home-estatisticas-frame3553,
  .home-estatisticas-frame3554 {
    gap: 0;
  }

  .home-estatisticas-frame301, .home-estatisticas-frame326, .home-estatisticas-frame327, .home-estatisticas-frame306 {
    gap: 24px;
  }

  .home-estatisticas-frame358 {
    gap: 12px;
  }

  .home-estatisticas-home-estatisticas, .home-estatisticas-frame325,
  .home-estatisticas-frame3061 {
    gap: 18px;
  }

  .HeaderH1-24Medium {
    font-size: 19px;
  }
  .home-estatisticas-buttons6 {
    padding: 6px 12px;
  }
  .home-estatisticas-buttons6 .home-estatisticas-text50 {
    font-size: 14px;
  }

  .home-estatisticas-frame3061, .home-estatisticas-frame306, .home-estatisticas-frame327,
  .home-estatisticas-frame326, .home-estatisticas-frame301 {
    padding: 16px;
  }

  .home-frame319 {
    padding: 24px;
  }
}