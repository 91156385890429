.videoPlayer {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: #000;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.youtubePlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoPlayer .progressBarWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 20;
  height: 10px;
}

.videoPlayer .progressBar {
  position: relative;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  overflow: hidden;
}

.videoPlayer .progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #28a745;
  transition: width 0.1s linear;
}

.videoPlayer .progressThumb {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  width: 16px;
  height: 16px;
  background-color: #28a745;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(40, 167, 69, 0.3);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.videoPlayer:hover .progressThumb {
  opacity: 1;
}

.videoPlayer .controls {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 10;
}

.videoPlayer .controls.show {
  opacity: 1;
}

.videoPlayer button {
  position: relative;
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.videoPlayer button:hover {
  color: #28a745;
  transform: scale(1.1);
}

.videoPlayer button .tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

.videoPlayer button:hover .tooltip {
  opacity: 1;
}

.videoPlayer .playbackRate {
  font-size: 18px;
  font-weight: bold;
}

.videoPlayer .volumeBarWrapper {
  position: relative;
  width: 100px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.videoPlayer .volumeBar {
  position: relative;
  height: 100%;
  width: 100%;
}

.videoPlayer .volume {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #28a745;
  border-radius: 4px;
  transition: width 0.1s linear;
}

.videoPlayer .volumeThumb {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  width: 16px;
  height: 16px;
  background-color: #28a745;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(40, 167, 69, 0.3);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.videoPlayer:hover .volumeThumb {
  opacity: 1;
}

.leftControls {
  display: flex;
  align-items: center;
}

.rightControls {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .videoPlayer .controls {
    flex-wrap: wrap;
  }

  .videoPlayer button {
    margin: 5px;
  }
}