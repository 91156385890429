.tutorialOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
  }
  
  .tutorialContent {
    background: white;
    padding: 2rem;
    border-radius: 20px;
    max-width: 600px;
    width: 90%;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }
  
  .title {
    font-size: 2rem;
    color: #68b43a;
    margin-bottom: 1rem;
  }
  
  .description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
  .button {
    background-color: #68b43a;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #5a9c2e;
  }
  
  .icon {
    font-size: 3rem;
    color: #68b43a;
    margin-bottom: 1rem;
  }