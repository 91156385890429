@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.comunidade {
  width: 100%;
  display: flex;
  overflow: auto;
  background: linear-gradient(150deg, rgba(245, 255, 240, 1) 0%, rgba(207, 213, 170, 1) 100%);
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.comunidade__head {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 0;
  justify-content: center;
  background-color: #ffffff;
}

.comunidade__column {
  gap: 20px;
  width: 100%;
  max-width: 1248px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.comunidade__row {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.comunidade__search-bar {
  gap: 8px;
  flex: 1;
  display: flex;
  padding: 8px 16px;
  align-self: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--dl-color-gray-g30);
}

.comunidade__search-icon {
  width: 24px;
  height: 24px;
}

.comunidade__input {
  width: 100%;
  color: #060a03;
  font-family: "Inter";
  line-height: 24px;
  background-color: transparent;
}

.comunidade__notifications {
  width: 42px;
  height: 38px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.comunidade__indicator {
  top: 4px;
  right: 8px;
  width: 10px;
  height: 10px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  border-radius: 50%;
  background-color: #68b43a;
}

.comunidade__notification-icon {
  fill: #d2d2d2;
  width: 28px;
  height: 28px;
}

.comunidade__filters {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.comunidade__filter {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  padding: 8px 16px;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.comunidade__filter.active,
.comunidade__filter:hover {
  background-color: rgba(245, 255, 240, 1);
}

.comunidade__filter-icon {
  fill: rgba(207, 213, 170, 1);
  width: 24px;
  height: 24px;
  transition: fill 0.3s;
}

.comunidade__filter.active .comunidade__filter-icon,
.comunidade__filter:hover .comunidade__filter-icon {
  fill: rgba(141, 175, 102, 1);
}


.comunidade__filter-text {
  color: rgba(207, 213, 170, 1);
  font-size: 16px;
  font-family: "Inter";
  font-weight: 600;
  line-height: 24px;
  transition: color 0.3s;
}

.comunidade__filter.active .comunidade__filter-text,
.comunidade__filter:hover .comunidade__filter-text {
  color: rgba(141, 175, 102, 1);
}


.comunidade__posts {
  gap: 24px;
  width: 100%;
  max-width: 1248px;
  display: flex;
  padding: 32px 24px;
  align-items: flex-start;
  flex-direction: column;
  animation: fadeIn 0.5s ease-in-out;

}

.comunidade__card {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  align-self: center;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-default-white);
}

.comunidade__card-header {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.comunidade__card-author {
  gap: 12px;
  display: flex;
  align-items: flex-start;
}

.comunidade__author-image {
  width: 54px;
  height: 54px;
  object-fit: cover;
  border-radius: 50%;
}

.comunidade__author-info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.comunidade__author-name {
  color: var(--dl-color-default-black);
  font-size: 17px;
  font-family: "Inter";
  font-weight: 600;
  line-height: 24px;
}

.comunidade__post-date {
  color: #929491;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 24px;
}

.comunidade__more-options {
  fill: #929491;
  width: 20px;
  height: 32px;
}

.comunidade__card-body {
  gap: 4px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.comunidade__post-title {
  color: var(--dl-color-default-black);
  font-size: 17px;
  font-family: "Inter";
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 4px;
}

.comunidade__post-content {
  color: var(--dl-color-default-black);
  font-size: 17px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 24px;
}

.comunidade__post-tags {
  color: #b5c779;
  font-size: 17px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 24px;
}

.comunidade__post-images {
  gap: 8px;
  width: 100%;
  display: flex;
  max-height: 206px;
  align-items: flex-start;
  padding-top: 6px;
}

.comunidade__post-image-container {
  display: flex;
  position: relative;
  align-items: flex-start;
}

.comunidade__post-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 8px;
}

.comunidade__view-more {
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.65);
}

.comunidade__view-more-text {
  color: #ffffff;
  font-size: 32px;
  font-family: "Inter";
  font-weight: 500;
}

.comunidade__card-footer {
  gap: 20px;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.comunidade__likes,
.comunidade__comments {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.comunidade__like-icon,
.comunidade__comment-icon {
  fill: #d2d2d2;
  width: 24px;
  height: 24px;
}

.comunidade__like-count,
.comunidade__comment-count {
  color: #929491;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  line-height: 24px;
}

.comunidade__add-post {
  right: 16px;
  width: 60px;
  bottom: 16px;
  height: 60px;
  display: flex;
  position: fixed;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: #68b43a;
}

.comunidade__add-post-icon {
  fill: #ffffff;
  width: 28px;
  cursor: pointer;
  height: 28px;
}

.comunidade__author-admin-badge {
  padding: 2px 8px;
  background-color: #68b43a;
  color: #ffffff;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.comunidade__delete-icon {
  fill: var(--dl-color-gray-g300);
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s, fill 0.3s;
}

.comunidade__delete-icon:hover {
  fill: #ff0000;
  transform: scale(1.1);
}

.comunidade__matching-comments {
  margin-top: 16px;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  animation: fadeIn 0.3s ease-in-out;
}

.comunidade__matching-comments h4 {
  margin-bottom: 8px;
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

.comunidade__comment-preview {
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s;
}

.comunidade__comment-preview:last-child {
  border-bottom: none;
}

.comunidade__comment-preview:hover {
  background-color: #e8e8e8;
}


.comunidade__comment-author {
  font-weight: bold;
  color: #555;
  margin-bottom: 4px;
}

.comunidade__comment-content {
  color: #333;
  font-size: 15px;
  line-height: 1.4;
}

mark {
  background-color: yellow;
  color: black;
}

.comunidade__pin-icon {
  fill: var(--dl-color-gray-g300);
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s, fill 0.3s;
}

.comunidade__pin-icon:hover {
  fill: #68b43a;
  transform: scale(1.1);
}

.controls {
  display: flex;
  gap: 4px;
  align-items: center;
}


@media (max-width: 767px) {
  .comunidade__filters {
    flex-wrap: wrap;
  }

  .comunidade__filter {
    flex: 1;
  }

  .comunidade__post-images {
    flex-direction: column;
    max-height: none;
  }

  .comunidade__post-image {
    width: 100%;
    height: auto;
  }

  .comunidade__post-image-container {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .comunidade__filter-text {
    max-height: 24px;
  }

  .comunidade__card {
    padding: 16px;
  }

  .comunidade__post-content,
  .comunidade__post-tags {
    font-size: 15px;
  }

  .comunidade__post-title {
    font-size: 16px;
  }

  .comunidade__author-name,
  .comunidade__post-date {
    font-size: 15px;
  }
}

.comunidade__author-initials {
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.comunidade__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
}

.comunidade__empty-state-image {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.comunidade__empty-state-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.comunidade__empty-state-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.comunidade__empty-state-button {
  padding: 10px 20px;
  background-color: #68b43a;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.comunidade__empty-state-button:hover {
  background-color: #5a9e32;
}

.comunidade__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.comunidade__loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #68b43a;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.comunidade__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  margin-top: 20px;
}

.comunidade__loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #68b43a;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.comunidade__like-icon {
  width: 24px;
  height: 24px;
  transition: color 0.3s ease;
}

.comunidade__like-icon.liked {
  fill: #68b43a;
}

.comunidade__likes {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.comunidade__like-count {
  margin-left: 5px;
}

.comunidade__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  margin: 40px auto;
  max-width: 500px;
  width: 100%;
}

.comunidade__empty-state-icon {
  font-size: 80px;
  color: #68b43a;
  margin-bottom: 24px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.comunidade__empty-state-title {
  font-size: 28px;
  font-weight: 700;
  color: #333;
  margin-bottom: 16px;
}

.comunidade__empty-state-description {
  font-size: 18px;
  color: #666;
  margin-bottom: 32px;
  line-height: 1.5;
}

.comunidade__empty-state-button {
  padding: 12px 24px;
  background-color: #68b43a;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(104, 180, 58, 0.3);
}

.comunidade__empty-state-button:hover {
  background-color: #5a9e32;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(104, 180, 58, 0.4);
}

.comunidade__empty-state-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 8px rgba(104, 180, 58, 0.4);
}

@media (max-width: 768px) {
  .comunidade__empty-state {
    padding: 40px 20px;
  }

  .comunidade__empty-state-icon {
    font-size: 60px;
  }

  .comunidade__empty-state-title {
    font-size: 24px;
  }

  .comunidade__empty-state-description {
    font-size: 16px;
  }

  .comunidade__empty-state-button {
    font-size: 16px;
    padding: 10px 20px;
  }
}
