.userCard {
    background-color: #ffffff;
    border-radius: 24px;
    padding: 2rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    position: relative;
    overflow: hidden;
  }
  
  .userCard:hover {
    transform: translateY(-8px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  }
  
  .userCard.notApproved {
    opacity: 0.85;
    filter: grayscale(20%);
  }
  
  .cardHeader {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-right: 5.5rem;
  }
  
  .profileImage {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1.5rem;
    flex-shrink: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .profilePicture {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .profilePicture:hover {
    transform: scale(1.05);
  }
  
  .profileAcronym {
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #6c757d, #495057);
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: 700;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .userInfo {
    flex-grow: 1;
    min-width: 0;
  }
  
  .userName {
    font-size: 22px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .socialMedia {
    display: flex;
    gap: 1rem;
  }
  
  .socialIcon {
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .socialIcon:hover {
    transform: scale(1.15);
  }
  
  .socialIconImage {
    height: 28px;
    width: 28px;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  }
  
  .cardBody {
    margin-bottom: 1.5rem;
  }
  
  .lastLogin {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }
  
  .lastLogin::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #68b43a;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  
  .salesInfo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1rem;
  }
  
  .infoRow {
    background-color: #f8f9fa;
    border-radius: 12px;
    padding: 0.75rem;
    transition: background-color 0.3s ease;
  }
  
  .infoRow:hover {
    background-color: #e9ecef;
  }
  
  .infoLabel {
    color: #6c757d;
    font-size: 0.85rem;
    display: block;
    margin-bottom: 0.25rem;
  }
  
  .infoValue {
    font-weight: 700;
    color: #333333;
    font-size: 1.1rem;
  }
  
  .cardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
  }
  
  .viewMoreButton {
    background-color: #68b43a;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    box-shadow: 0 4px 6px rgba(104, 180, 58, 0.2);
  }
  
  .viewMoreButton:hover {
    background-color: #5a9e32;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(104, 180, 58, 0.3);
  }
  
  .whatsappButton {
    background-color: #25d366;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 4px 6px rgba(37, 211, 102, 0.2);
  }
  
  .whatsappButton:hover {
    background-color: #128c7e;
    transform: translateY(-2px) scale(1.05);
    box-shadow: 0 6px 12px rgba(37, 211, 102, 0.3);
  }
  
  .userStatus {
    position: absolute;
    right: 2rem;
    top: 2rem;
    z-index: 10;
  }
  
  .statusButton {
    background-color: #68b43a;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .statusButton.inactive {
    background-color: #dc3545;
  }
  
  .statusButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .userApproval {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    gap: 0.75rem;
    z-index: 10;
  }
  
  .approveButton,
  .rejectButton {
    background-color: #ffffff;
    border: none;
    border-radius: 50%;
    font-size: 1.5rem;
    cursor: pointer;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .approveButton {
    color: #68b43a;
  }
  
  .rejectButton {
    color: #dc3545;
  }
  
  .approveButton:hover,
  .rejectButton:hover {
    transform: translateY(-2px) scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }

  .pointsButtons {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .addPointsButton,
  .removePointsButton {
    background-color: #28a745;
    color: #ffffff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .addPointsButton {
    background-color: #28a745;
  }
  
  .removePointsButton {
    background-color: #dc3545;
  }
  
  .addPointsButton:hover,
  .removePointsButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .userNameContainer {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .inactiveWarning {
    font-size: 1.2rem;
    cursor: help;
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  .communityToggle {
    position: relative;
    width: 60px;
    height: 34px;
    background-color: #ccc;
    border-radius: 34px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .communityToggle.active {
    background-color: #25D366;
  }
  
  .communityToggle .toggleSlider {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: 0.3s;
  }
  
  .communityToggle.active .toggleSlider {
    transform: translateX(26px);
  }
  
  .communityToggle svg {
    color: white;
    font-size: 20px;
    z-index: 1;
    transition: 0.3s;
  }
  
  .communityToggle.active svg {
    color: #25D366;
  }

  .dateInfo {
    background-color: #f8f9fa;
    border-radius: 12px;
    padding: 0.75rem;
    margin-bottom: 1rem;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
   }
   
   .dateInfo:hover {
    background-color: #e9ecef;
   }
   
   .dateInfo .infoLabel {
    color: #6c757d;
    font-size: 0.85rem;
    display: block;
    margin-bottom: 0.25rem;
   }
   
   .dateInfo .infoValue {
    font-weight: 700;
    color: #333333;
    font-size: 1.1rem;
   }