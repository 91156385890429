.image-viewer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

.image-viewer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100%;
  gap: 16px;
  position: relative;
  opacity: 0;
  transform: scale(0.8);
  animation: scaleIn 0.3s forwards;
}

.image-viewer__image-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-viewer__image {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 8px;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
  animation: imageFadeIn 0.3s forwards;
}

.image-viewer__arrow {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.image-viewer__arrow:hover {
  background-color: rgba(0, 0, 0, 0.6);
  transform: scale(1.1);
}

.image-viewer__arrow--disabled {
  background-color: rgba(0, 0, 0, 0.34);
  cursor: not-allowed;
}

.image-viewer__icon {
  width: 28px;
  height: 28px;
  fill: #fff;
}

.image-viewer__arrow--disabled .image-viewer__icon {
  fill: rgba(255, 255, 255, 0.48);
}

.image-viewer__close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s;
}

.image-viewer__close:hover .image-viewer__close-icon {
  transform: rotate(90deg);
}

.image-viewer__close-icon {
  width: 32px;
  height: 32px;
  fill: #fff;
  transition: fill 0.3s;
}

.image-viewer__close-icon:hover {
  fill: rgba(255, 255, 255, 0.7);
}

@media (max-width: 991px) {
  .image-viewer__image {
    max-height: 70vh;
  }
}

@media (max-width: 767px) {
  .image-viewer__image {
    max-height: 60vh;
  }
}

@media (max-width: 479px) {
  .image-viewer__image {
    max-height: 50vh;
  }
  .image-viewer__arrow {
    width: 45px;
    height: 45px;
  }
  .image-viewer__icon {
    width: 22px;
    height: 22px;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes imageFadeIn {
  to {
    opacity: 1;
  }
}
