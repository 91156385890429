.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.popupnp-content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  gap: 32px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.03);
  overflow: scroll;
  /* Oculta a barra de rolagem */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.popupnp-content::-webkit-scrollbar {
  display: none;
}

.popupnp-title {
  font-size: 24px;
  font-weight: 500;
  color: #060a03;
  line-height: 32px;
}

.popup-inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.popup-input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.popup-label {
  font-size: 16px;
  line-height: 16px;
  color: #060a03;
}

.popup-input,
.popup-textarea {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 16px;
  background-color: #ebebeb;
  color: #060a03;
  line-height: 24px;
  outline:none;
}

.popup-textarea {
  resize: none;
  min-height: 100px;
}

.popup-tags-input {
  display: flex;
  gap: 16px;
}

.popup-add-tag-button {
  background-color: #68b43a;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: center;
}

.popup-add-tag-icon {
  fill: #ffffff;
  width: 16px;
  height: 16px;
}

.popup-tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.popup-tag {
  background-color: #f0f0f0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 4px;
}

.popup-tag-text {
  font-size: 14px;
}

.popup-remove-tag-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  align-self: center;
  align-items: center;
  display: flex;
}

.popup-remove-tag-icon {
  fill: #999999;
  width: 16px;
  height: 16px;
  align-self: center;
}

.popup-upload-area {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.popup-upload-icon {
  fill: #ccc;
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
}

.popup-upload-text {
  font-size: 14px;
  color: #666666;
  margin-bottom: 4px;
}

.popup-upload-limit {
  font-size: 12px;
  color: #999999;
}

.popup-images-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.popup-image {
  position: relative;
  width: 80px;
  height: 80px;
}

.popup-image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.03);
}

.popup-remove-image-button {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}

.popup-remove-image-icon {
  fill: #999999;
  width: 16px;
  height: 16px;
}

.popupnp-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.popup-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.popup-cancel-button {
  background-color: #f0f0f0;
  color: #666666;
  border: none;
}

.popup-publish-button {
  background-color: #68b43a;
  color: #ffffff;
  border: none;
}

.popup-error-message {
  color: red;
  font-size: 14px;
  margin-top: 8px;
}

@media (max-width: 480px) {

  .popupnp-content {
    margin: 0 16px;
  }
  .popupnp-buttons {
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: 8px;
    width: 100%;
  }

  .popup-button {
    width: 100%;
  }

  .popup-input {
    width: 100%;
  }
}
