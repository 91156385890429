.usuariosMain {
    min-height: 100vh;
    background: linear-gradient(150deg, #f5fff0, #cfd5aa);
  }
  
  .usuariosContainer {
    max-width: 1248px;
    margin: 0 auto;
    padding: 2rem 24px;
  }
  
  .usuariosTitle {
    font-size: 1.875rem;
    color: #223a13;
    margin-bottom: 2rem;
    text-align: left;
  }
  
  @media screen and (max-width: 768px) {
    .usuariosTitle {
      font-size: 1.5rem;
    }
  }