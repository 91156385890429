.help-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(150deg, rgba(245, 255, 240, 1) 0%, rgba(207, 213, 170, 1) 100%);
}

.help-header {
  height: 100px;
  background-color: #ffffff;
}

.help-content {
  display: flex;
  flex-grow: 1;
}

.help-info {
  position: relative;
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
  background-image: url('/bodydryproduto.jpeg');
  background-size: cover;
  background-position: center;
}

.help-info-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(32, 51, 20, 0.8);
  z-index: 1;
}

.help-info-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 410px;
  text-align: center;
  color: white;
  gap: 16px;
}

.help-info-title {
  font-size: 27px;
  font-weight: 700;
}

.help-info-description {
  font-size: 18px;
  line-height: 1.5;
  color: rgb(227, 227, 227);
}

.help-info-image {
  width: 200px;
  margin-top: 32px;
}

.help-form-container {
  flex: 2;
  display: flex;
  justify-content: center;
  padding: 32px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  
}

.help-form {
  max-width: 450px;
  width: 100%;
  align-self: center;
  gap: 44px;
  display: flex;
  flex-direction: column;
}

.help-form-header {
  text-align: center;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.help-form-title {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: center;
  line-height: 32px;
  font-size: 30px;
  color: #223a13;
  font-weight: 600;
}

.help-form-description {
  font-size: 16px;
  color: rgb(106, 108, 104);
}

.help-form-fields {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.help-form-field {
  display: flex;
  flex-direction: column;
}

.help-form-label {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
}

.help-form-input,
.help-form-textarea {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background-color: #ebebeb;
  font-family: "Inter";
  line-height: 24px;
  outline: none;
}

.help-form-textarea {
  resize: none;
}

.help-form-file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  border: 2px dashed #ebebeb;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
}

.help-form-file-input {
  display: none;
}

.help-form-file-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  cursor:pointer;
}

.help-form-file-icon {
  fill: #ebebeb;
  width: 40px;
  height: 44px;
}

.help-form-file-text {
  font-size: 14px;
  color: rgb(106, 108, 104);
}

.help-form-file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.help-form-file-item {
  position: relative;
  width: 56px;
  height: 56px;
}

.help-form-file-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.help-form-file-remove {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.help-form-file-remove-icon {
  fill: #ab3e3e;
  width: 16px;
  height: 16px;
}

.help-form-submit-button {
  position: relative;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.help-form-submit-button:hover {
  background-color: #218838;
}

.help-form-submit-button:disabled {
  cursor: not-allowed;
  background-color: #5aaf69;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 4px;
  background-color: #fff;
  border-radius: 50%;
  animation: bounce 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes bounce {
  0%, 60%, 100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-15px);
  }
}

@media (max-width: 991px) {
  .help-info {
    display: none;
  }

  .help-form-container {
    flex: 1;
  }

  .help-form {
    align-self: flex-start;
    max-width: 100%;
  }
}

.icon-star {
  fill: gold;
}

@media (max-width: 546px) {
  .help-form-file-upload {
    text-align: center;
  }

  .help-form-title {
    font-size: 24px;
  }

  .help-form-description {
    font-size: 14px;
  }

  .help-form-header {
    gap: 4px;
  }

  .help-form-container {
    width: 100%;
    margin: 24px;
  }
}