.searchContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .searchInput {
    width: 100%;
    max-width: 500px;
    padding: 0.75rem 1rem 0.75rem 3rem;
    border: none;
    border-radius: 12px;
    font-size: 1rem;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .searchInput:focus {
    outline: none;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .searchIcon {
    position: absolute;
    left: calc(50% - 235px);
    top: 50%;
    transform: translateY(-50%);
    color: #68b43a;
    font-size: 1.5rem;
  }
  
  @media screen and (max-width: 768px) {
    .searchInput {
      max-width: 100%;
    }
  
    .searchIcon {
      left: 1rem;
    }
  }