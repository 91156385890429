.perfil-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;background: linear-gradient(150deg, rgba(245, 255, 240, 1) 0%, rgba(207, 213, 170, 1) 100%);
  background-color: var(--dl-color-gradient-primary);
}
.perfil-painel-meuperfil {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.perfil-mobile {
  gap: 32px;
  display: flex;
  padding: 24px 16px;
  align-self: stretch;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 1);
}
.perfil-frame327 {
  gap: 32px;
  display: flex;
  align-items: center;
}
.perfil-l2 {
  width: 101px;
  height: 48px;
}
.perfil-frame334 {
  gap: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.perfil-menu {
  width: 20px;
  height: 20px;
}
.perfil-frame319 {
  width: 100%;
  gap: 40px;
  display: flex;
  padding: 32px 24px;
  align-self: stretch;
  align-items: flex-start;
  max-width: 1248px;
  flex-direction: column;
  margin: 0 auto;
}
.perfil-text {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 32px;
  font-size: 30px;
  color: #223a13;
  font-weight: 600;
}
.perfil-frame321 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.perfil-frame326 {
  gap: 16px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  align-self: stretch;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-default-white);
}
.perfil-frame355 {
  gap: 4px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.perfil-text2 {
  color: var(--dl-color-default-black);
  height: auto;
  text-align: left;
  line-height: 24px;
}
.perfil-frame356 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.perfil-frame303 {
  gap: 8px;
  width: 44px;
  display: flex;
  padding: 10px 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-color: rgba(193, 194, 192, 1);
  border-style: solid;
  border-width: 2px;
  border-radius: 800px;
  justify-content: center;
}
.perfil-person {
  width: 24px;
  height: 24px;
}
.perfil-frame357 {
  gap: 4px;
  width: 228px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.perfil-text4 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.perfil-text6 {
  color: var(--dl-color-gray-g200);
  height: auto;
  text-align: left;
  line-height: 16px;
}

.excluir-conta-buttons1 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  border-radius: 4px;
  background-color: #e45757;
}
.excluir-conta-text7 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: center;
  line-height: 24px;
}

.btns-admin {
  display:flex;
  gap:10px;
}

.perfil-tabs {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
}

.perfil-tab {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  background-color: transparent;
  color: #6c757d;
  cursor: pointer;
  transition: color 0.3s ease;
}

.perfil-tab.active {
  color: #343a40;
  border-bottom: 2px solid #343a40;
}

.perfil-frame321 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

.perfil-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 2rem;
  width: 100%;
  }

  .perfil-sidebar {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  height: fit-content;
  }
  .perfil-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  }
  .perfil-user-avatar {
    width: 100%;
    height: 100%;
    font-size: 3rem;  
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 16px;
  }
  .perfil-user-avatar-acronym {
  width: 8rem;
  height: 8rem;
  font-size: 3rem;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #333;
  margin-bottom: 16px;
  }
  .perfil-user-name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
  }
  .perfil-user-email {
  font-size: 1rem;
  color: #777;
  }
  .perfil-nav {
  margin-bottom: 24px;
  }
  .perfil-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  }
  .perfil-nav-item {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  }
  .perfil-nav-item:hover {
  background-color: #f5f5f5;
  }
  .perfil-nav-item.active {
  background-color: #e0e0e0;
  }
  .perfil-nav-icon {
  font-size: 1.25rem;
  margin-right: 12px;
  color: #333;
  }
  .perfil-nav-item span {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  }
  .perfil-remove-account-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: #dc3545;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  }
  .perfil-remove-account-btn:hover {
  background-color: #c82333;
  }
  .perfil-remove-account-icon {
  font-size: 1.25rem;
  margin-right: 8px;
  }
  .perfil-main-content {
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  }
  .perfil-tab-title {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 24px;
  color: #333;
  }
  .perfil-referral-info {
  margin-bottom: 1.5rem;
  }
  .perfil-referral-info h3 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 12px;
  color: #333;
  }
  .perfil-referral-user {
  display: flex;
  align-items: center;
  flex-direction:column;
  gap: 8px;
  background: #f1f1f1;
  padding:1rem;
  border-radius:5px;
  text-align:center;
  }
  .perfil-referral-user-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
  }
  .perfil-referral-user-avatar-acronym {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-right: 12px;
  }
  .perfil-referral-user-name {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #333;
  }
  .perfil-referral-user-email {
  font-size: 14px;
  color: #777;
  }
  .perfil-metrics-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
  }
  .perfil-metric-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  .perfil-metric-card-highlight {
  background-color: #e9f7ef;
  border: 2px solid #28a745;
  }
  .perfil-metric-title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 12px;
  color: #333;
  }
  .perfil-metric-value {
  font-size: 1.75rem;
  font-weight: 600;
  color: #28a745;
  }
  .perfil-chart-container {
  margin-top: 32px;
  }
  .perfil-chart-title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 16px;
  color: #333;
  }
  .perfil-password-form {
  max-width: 400px;
  }
  .perfil-form-group {
  margin-bottom: 24px;
  }
  .perfil-form-group label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #333;
  }
  .perfil-form-input {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  }
  .perfil-form-submit-btn {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  }
  .perfil-form-submit-btn:hover {
  background-color: #218838;
  }
  
  .perfil-forms {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .perfil-forms > * {
    border: 1px solid #ebebeb;
  }

  .adm-user-profile-picture {
    position: relative;
    width: 8rem;
    height: 8rem;
    margin-bottom: 1rem;
  }

  .adm-user-profile-picture .perfil-user-avatar-remove-btn {
    position: absolute;
    top: 50%;
    right: -8px;
    transform: translateY(-50%);
    color: #fff;
    width: 24px;
    height:24px;
    background-color:#e45757a6;
    border-radius: 50%;
    cursor: pointer;
  }

  .adm-user-profile-picture .perfil-user-avatar-remove-btn:hover {
    background-color:#e45757;
  }

  @media screen and (max-width: 768px) {
    .adm-user-profile-picture .perfil-user-avatar-remove-btn:hover {
      background-color:#e45757;
    }  
  }

  @media screen and (min-width: 768px) {
    .perfil-content {
      flex-direction: row;
    }
  
    .perfil-sidebar {
      margin-bottom: 0;
      min-width: 20rem;
      width: auto;
    }

    .perfil-referral-user {
      flex-direction:row;
      gap: 0px;
      background: none;
      padding:0;
      border-radius:0;
      text-align:left;
      }
  }