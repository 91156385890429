.perfil-form-dados-pessoais-perfil-form-dados-pessoais {
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-white);
}
.perfil-form-dados-pessoais-frame354 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
  cursor: pointer;
}
.perfil-form-dados-pessoais-text {
  color: var(--dl-color-default-black);
  height: auto;
  text-align: left;
  line-height: 24px;
}
.perfil-form-dados-pessoais-keyboardarrowdown {
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
}
.perfil-form-dados-pessoais-keyboardarrowup {
  width: 24px;
  height: 24px;
}
.perfil-form-dados-pessoais-frame308 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid var(--dl-color-gray-900);
  padding-top: 32px;
}
.perfil-form-dados-pessoais-text02 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.perfil-form-dados-pessoais-frame309 {
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.perfil-form-dados-pessoais-textfields {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.perfil-form-dados-pessoais-text04 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-pessoais-textfields1 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.perfil-form-dados-pessoais-text06 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-pessoais-frame2 {
  gap: 0;
  height: 40px;
  display: flex;
  overflow: hidden;
  max-width: 151px;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  padding-top: 0px;
  padding-left: 16px;
  border-radius: 4px;
  padding-right: 0px;
  padding-bottom: 0px;
  background-color: var(--dl-color-gray-g30);
}
.perfil-form-dados-pessoais-calendartoday {
  width: 24px;
  height: 24px;
}
.perfil-form-dados-pessoais-textinput1 {
  height: 100%;
  padding-left: 8px;
  border-radius: 0px;
  padding-right: 16px;
}
.perfil-form-dados-pessoais-textfields2 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.perfil-form-dados-pessoais-text08 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-pessoais-frame348 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.perfil-form-dados-pessoais-selected {
  width: 24px;
  height: 24px;
}
.perfil-form-dados-pessoais-none {
  width: 24px;
  height: 24px;
}
.perfil-form-dados-pessoais-text10 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: left;
  line-height: 24px;
}
.perfil-form-dados-pessoais-frame3481 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.perfil-form-dados-pessoais-selected1 {
  width: 24px;
  height: 24px;
}
.perfil-form-dados-pessoais-none1 {
  width: 24px;
  height: 24px;
}
.perfil-form-dados-pessoais-text11 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: left;
  line-height: 24px;
}
.perfil-form-dados-pessoais-frame3482 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.perfil-form-dados-pessoais-selected2 {
  width: 24px;
  height: 24px;
}
.perfil-form-dados-pessoais-none2 {
  width: 24px;
  height: 24px;
}
.perfil-form-dados-pessoais-text12 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: left;
  line-height: 24px;
}
.perfil-form-dados-pessoais-frame311 {
  gap: 16px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.perfil-form-dados-pessoais-textfields3 {
  gap: 8px;
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.perfil-form-dados-pessoais-text13 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-pessoais-text15 {
  color: var(--dl-color-gray-g300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 14px;
}
.perfil-form-dados-pessoais-textfields4 {
  gap: 8px;
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.perfil-form-dados-pessoais-text17 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-pessoais-text19 {
  color: var(--dl-color-gray-g300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 14px;
}
.perfil-form-dados-pessoais-textfields5 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.perfil-form-dados-pessoais-text21 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-pessoais-textfields6 {
  gap: 8px;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.perfil-form-dados-pessoais-text23 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-pessoais-textinput5 {
  max-width: 340px;
}
.perfil-form-dados-pessoais-text25 {
  color: var(--dl-color-gray-g300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 14px;
}
.perfil-form-dados-pessoais-frame3091 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.perfil-form-dados-pessoais-text27 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.perfil-form-dados-pessoais-textfields7 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.perfil-form-dados-pessoais-text29 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-pessoais-text31 {
  color: var(--dl-color-gray-g300);
  height: auto;
  align-self: stretch;
  text-align: left;
  cursor:pointer;
  line-height: 14px;
}
.perfil-form-dados-pessoais-frame306 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
}
.perfil-form-dados-pessoais-buttons {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  max-width: 200px;
  border-color: rgba(162, 62, 62, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.perfil-form-dados-pessoais-text33 {
  color: var(--dl-color-error-error700);
  height: auto;
  flex-grow: 1;
  text-align: center;
  line-height: 24px;
}
.perfil-form-dados-pessoais-buttons1 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: rgba(104, 180, 58, 1);
}
.perfil-form-dados-pessoais-text35 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: center;
  line-height: 24px;
}
@media(max-width: 991px) {
  .perfil-form-dados-pessoais-frame2 {
    min-width: 100%;
  }
  .perfil-form-dados-pessoais-frame311 {
    flex-direction: column;
  }
  .perfil-form-dados-pessoais-textinput5 {
    min-width: 100%;
  }
  .perfil-form-dados-pessoais-frame306 {
    flex-direction: column;
  }
}
.perfil-form-dados-pessoais-photo-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Ajuste conforme necessário */
}

.perfil-form-dados-pessoais-profile-photo-preview {
  width: 100px; /* Ajuste para o tamanho desejado */
  height: 100px; /* Ajuste para o tamanho desejado */
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px; /* Espaço entre a imagem e o botão */
}

.perfil-form-dados-pessoais-alterar-foto-btn {
  color: #68b43a;
  padding: 8px 16px;
  border: 1px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  border-color: #68b43a;
  border-style: solid
}

.page-cadastro-form-step2-icon {
  width: 40px;
}

.page-cadastro-form-step2-frame3094, .page-cadastro-form-step2-frame3093 {
  width: 100%;
}

.page-cadastro-form-step2-frame3094 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.page-cadastro-form-step2-social-input {
  display: flex;
  align-items:flex-end;
  gap: 8px;
  width: 100%;
}

.page-cadastro-form-step2-input-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.page-cadastro-form-step2-header {
  margin-bottom: 1.5rem;
}