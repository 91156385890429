.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 20px;
  }
  
  .card {
    background: white;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 600px;
    width: 100%;
    text-align: center;
  }
  
  .logoContainer {
    margin-bottom: 30px;
  }
  
  .logo {
    max-width: 150px;
  }
  
  .title {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .messageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  
  .icon {
    font-size: 24px;
    color: #e74c3c;
    margin-right: 10px;
  }
  
  .message {
    color: #555;
    font-size: 18px;
  }
  
  .benefitsContainer {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
  }
  
  .benefitsTitle {
    color: #333;
    font-size: 22px;
    margin-bottom: 15px;
  }
  
  .benefitsList {
    list-style-type: none;
    padding: 0;
    text-align: left;
  }
  
  .benefitsList li {
    display: flex;
    align-items: center;
    color: #555;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .benefitsList li svg {
    color: #27ae60;
    margin-right: 10px;
  }
  
  .whatsappButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #25D366;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    padding: 15px 30px;
    border-radius: 30px;
    transition: all 0.3s ease;
  }
  
  .whatsappButton:hover {
    background-color: #128C7E;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(37, 211, 102, 0.3);
  }
  
  .whatsappIcon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .activationMessage {
    color: #777;
    font-size: 14px;
    margin-top: 20px;
  }