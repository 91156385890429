.popup-excluir-post-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .popup-excluir-post-popup {
    background-color: #fff;
    border-radius: 10px;
    padding: 32px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .popup-excluir-post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .popup-excluir-post-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }
  
  .popup-excluir-post-close {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .popup-excluir-post-close-icon {
    width: 24px;
    height: 24px;
  }
  
  .popup-excluir-post-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .popup-excluir-post-message {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
  }
  
  .popup-excluir-post-description {
    font-size: 14px;
    color: #777;
    margin-top: 8px;
  }
  
  .popup-excluir-post-actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
  
  .popup-excluir-post-cancel {
    padding: 12px 24px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: transparent;
    font-size: 16px;
    color: #333;
    cursor: pointer;
  }
  
  .popup-excluir-post-confirm {
    padding: 12px 24px;
    border-radius: 6px;
    background-color: #dc3545;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
  
  @media screen and (max-width: 546px) {
    .popup-excluir-post-popup {
      padding: 24px;
      margin: 16px;
    }
  }
  