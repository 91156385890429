.academyAdmin {
  min-height: 100vh;
  background: linear-gradient(150deg, #f5fff0 0%, #cfd5aa 100%);
}

.container {
  max-width: 1248px;
  margin: 0 auto;
  padding: 2rem 24px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.title {
  font-size: 1.875rem;
  color: #223a13;
}

.previewButton {
  padding: 0.75rem 1.5rem;
  background-color: #68b43a;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.previewButton:hover {
  background-color: #5a9d32;
}

.tabs {
  display: flex;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab {
  flex: 1;
  padding: 1rem;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.activeTab {
  background-color: #68b43a;
  color: white;
}

.content {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 2rem;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.sectionTitle {
  font-size: 1.5rem;
  color: #223a13;
}

.addButton {
  padding: 0.5rem 1rem;
  background-color: #68b43a;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.addButton:hover {
  background-color: #5a9d32;
}

.filterSection {
  margin-bottom: 1rem;
}

.courseFilter {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  background-color: white;
  transition: border-color 0.3s ease;
}

.courseFilter:focus {
  border-color: #68b43a;
  outline: none;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table th,
.table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #e9ecef;
}

.table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
}

.table tr:last-child td {
  border-bottom: none;
}

.editButton,
.deleteButton {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 0.5rem;
}

.editButton {
  background-color: #17a2b8;
  color: white;
}

.editButton:hover {
  background-color: #138496;
}

.deleteButton {
  background-color: #dc3545;
  color: white;
}

.deleteButton:hover {
  background-color: #c82333;
}

.emptyState {
  text-align: center;
  padding: 3rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.emptyStateIcon {
  width: 64px;
  height: 64px;
  margin: 0 auto 1rem;
  color: #68b43a;
}

.emptyStateTitle {
  font-size: 1.5rem;
  color: #223a13;
  margin-bottom: 0.5rem;
}

.emptyStateDescription {
  color: #6c757d;
  margin-bottom: 1.5rem;
}

.emptyStateButton {
  padding: 0.75rem 1.5rem;
  background-color: #68b43a;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.emptyStateButton:hover {
  background-color: #5a9d32;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .table th,
  .table td {
    padding: 0.75rem 0.5rem;
  }

  .editButton,
  .deleteButton {
    padding: 0.4rem 0.8rem;
    font-size: 0.75rem;
  }
}