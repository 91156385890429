.userList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  @media screen and (max-width: 768px) {
    .userList {
      grid-template-columns: 1fr;
    }
  }