.statistics {
  gap: 24px;
  width: 100%;
  display: grid;
  align-self: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Ajuste para telas menores */
  align-items: stretch;
}

.card {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746)
}

.card-comissoes {
  grid-row: span 2;
}

.card-header {
  gap: 12px;
  width: 100%;
  display: flex;
  padding: 20px 24px;
  align-items: center;
  justify-content: flex-start;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
}

.card-header svg {
  fill: #68b43a;
  width: 24px;
  height: 24px;
}

.card-header h2 {
  color: var(--dl-color-default-black);
  font-size: 1.125rem;
  font-style: normal;
  text-align: left;
  font-family: "Inter";
  font-weight: 600;
  line-height: 1.5rem;
  font-stretch: normal;
  text-decoration: none;
}

.card-content {
  gap: 1.5rem;
  width: 100%;
  display: flex;
  padding: 1.5rem;
  align-items: flex-start;
  flex-direction: column;
}

.comission-details {
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.comission-item {
  gap: 0.5rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.comission-item h3 {
  color: var(--dl-color-default-black);
  font-size: 0.875rem;
  font-style: normal;
  text-align: left;
  font-family: "Inter";
  font-weight: 500;
  line-height: 1.125rem;
  font-stretch: normal;
  text-decoration: none;
}

.comission-item p {
  color: var(--dl-color-default-black);
  font-size: 1.5rem;
  font-style: normal;
  text-align: left;
  font-family: "Inter";
  font-weight: 600;
  line-height: 2rem;
  font-stretch: normal;
  text-decoration: none;
}

.rate-change {
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 14px;
}

.rate-change svg {
  fill: #326a3e;
  width: 1rem;
  height: 1rem;
}

.rate-change.negative svg {
  fill: #d32f2f;
}

.rate-change.negative {
  color: #d32f2f;
}

.rate-change.positive {
  color: #326a3e;
}

.goal-progress {
  gap: 0.5rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.goal-progress-bar {
  width: 100%;
  height: 0.75rem;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: row;
  background-color: #e0e0e0;
}

.progress {
  top: 0px;
  left: 0px;
  width: var(--progress);
  height: 100%;
  position: absolute;
  border-radius: 8px;
  background-color: #68b43a;
}

.goal-progress-label {
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.goal-progress-label img {
  width: 1rem;
  height: 1rem;
  object-fit: cover;
}

.goal-progress-label span {
  color: var(--dl-color-default-black);
  font-size: 0.875rem;
  font-style: normal;
  text-align: left;
  font-family: "Inter";
  font-weight: 400;
  line-height: 1.125rem;
  font-stretch: normal;
  text-decoration: none;
}

.goal-progress + p,
.card-academy p {
  color: #555;
  line-height: 1.4;
}

.info-button,
.view-all-button,
.view-courses-button,
.close-button {
  padding: 0.625rem 1rem;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.info-button,
.view-all-button,
.view-courses-button {
  color: #ffffff;
  border: 0;
  cursor: pointer;
}

.info-button {
  background-color: #17a2b8;
}

.info-button:hover {
  background-color: #138496;
}

.view-all-button,
.view-courses-button {
  background-color: #68b43a;
  margin-top: 1rem;
}

.view-all-button:hover,
.view-courses-button:hover {
  background-color: #5a9c2e;
}

.indication-count,
.coupon-item {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.indication-count svg {
  fill: #ffc658;
  width: 3rem;
  height: 3rem;
}

.indication-count p {
  color: var(--dl-color-default-black);
  font-size: 1.5rem;
  font-style: normal;
  text-align: left;
  font-family: "Inter";
  font-weight: 600;
  line-height: 2rem;
  font-stretch: normal;
  text-decoration: none;
}

.coupon-item {
  gap: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coupon-item h3 {
  color: var(--dl-color-default-black);
  font-size: 0.875rem;
  font-style: normal;
  text-align: left;
  font-family: "Inter";
  font-weight: 500;
  line-height: 1.125rem;
  font-stretch: normal;
  text-decoration: none;
}

.coupon-item p {
  color: var(--dl-color-default-black);
  font-size: 1.5rem;
  font-style: normal;
  text-align: right;
  font-family: "Inter";
  font-weight: 600;
  line-height: 2rem;
  font-stretch: normal;
  text-decoration: none;
}

.indication {
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.academy {
  gap: 1rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}

.coupons {
  gap: 1.5rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@media (max-width: 768px) {
  .statistics {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .card-comissoes {
    grid-row: span 1; 
  }
}

.gamification-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  backdrop-filter: blur(10px);
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.gamification-popup-content {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 40px;
  max-width: 768px;
  width: 90%; 
  position: relative;
  height: auto;
  max-height: 85%;
  overflow-y: auto;
}

.gamification-popup-content::-webkit-scrollbar {
  display: none;
}

.gamification-popup-content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.gamification-popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;
  font-size: 1.4rem;
}

.gamification-popup-close:hover {
  color: #68b43a;
}

.gamification-popup-title {
  color: #222;
  font-size: 1.8rem;
  margin-bottom: 1.2rem;
  text-align: center;
  font-weight: 600;
}

.gamification-popup-description {
  color: #555;
  font-size: 0.9rem;
  line-height: 1.6;
  margin-bottom: 2.5rem;
  text-align: center;
}

.gamification-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjusted for 3 columns */
  gap: 24px;
}

.gamification-card {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.2s ease;
}

.gamification-card:hover {
  transform: translateY(-5px); 
}

.gamification-card-icon {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.gamification-card-title {
  color: #333;
  font-size: 1.1rem; /* Reduced font size */
  font-weight: 600;
  margin-bottom: 8px;
}

.gamification-card-points {
  color: #68b43a;
  font-size: 0.9rem; /* Reduced font size */
  font-weight: 600;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .gamification-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Adjust for smaller screens */
  }
}

.academy-quiz-section {
  background-color: #f8f9fa;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2.5rem;
  margin-top: 3rem;
}

.academy-quiz-title {
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.academy-quiz-title-icon {
  color: #3498db;
  font-size: 1.75rem;
}

.academy-quiz-content {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.academy-quiz-steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.academy-quiz-step {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 1.75rem;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.academy-quiz-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.step-icon {
  color: #3498db;
  font-size: 2.25rem;
  margin-bottom: 1rem;
}

.academy-quiz-step p {
  color: #34495e;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  font-weight: 500;
}

.academy-quiz-example {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.quiz-progress-container {
  margin-bottom: 1.25rem;
}

.quiz-progress-bar {
  background-color: #e0e0e0;
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.quiz-progress {
  background: linear-gradient(90deg, #3498db, #2ecc71);
  height: 100%;
  border-radius: 5px;
  transition: width 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
}

.quiz-progress-labels {
  display: flex;
  justify-content: space-between;
  color: #7f8c8d;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  font-weight: 500;
}

.quiz-example-text {
  color: #34495e;
  font-size: 1rem;
  text-align: center;
  margin: 1rem 0 0;
  font-weight: 500;
}

@media (max-width: 768px) {
  .academy-quiz-steps {
    grid-template-columns: 1fr;
  }

  .academy-quiz-step {
    flex-direction: row;
    text-align: left;
    padding: 1.25rem;
  }

  .step-icon {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .academy-quiz-section {
    padding: 2rem;
  }
}

.gamification-card-points + small {
  color: #888;
  font-size: 12px;
  margin-top: 4px;
  font-weight:500;
  font-style: italic;
}


.generate-voucher-button {
  background-color: #68b43a;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.generate-voucher-button:hover {
  background-color: #5a9c2e;
  transform: translateY(-2px);
}

.generate-voucher-button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

.voucher-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.voucher-popup-content {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 2rem;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  position: relative;
}

.voucher-popup-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #555;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.voucher-popup-close:hover {
  color: #68b43a;
}

.voucher-popup-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.voucher-popup-content p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.voucher-copy-button {
  background-color: #68b43a;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  margin: 1rem auto 0;
  width: 100%;
}

.voucher-copy-button:hover {
  background-color: #5a9c2e;
}

.voucher-popup-content span[style*="cursor: pointer"] {
  color: #68b43a;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.voucher-popup-content span[style*="cursor: pointer"]:hover {
  color: #5a9c2e;
}

@media (max-width: 768px) {
  .voucher-popup-content {
    padding: 1.5rem;
    width: 95%;
  }
}

.generate-monthly-voucher-button {
  background-color: #ff9800;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.generate-monthly-voucher-button:hover {
  background-color: #f57c00;
  transform: translateY(-2px);
}

.monthly-voucher-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease-in-out;
}

.monthly-voucher-popup-content {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 2rem;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  position: relative;
}

.monthly-voucher-popup-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #555;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.monthly-voucher-popup-close:hover {
  color: #ff9800;
}

.monthly-voucher-popup-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.monthly-voucher-info {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.monthly-voucher-icon {
  font-size: 2.5rem;
  color: #ff9800;
  margin-right: 1rem;
}

.monthly-voucher-details,
.monthly-voucher-instructions {
  margin-bottom: 1.5rem;
}

.monthly-voucher-details h3,
.monthly-voucher-instructions h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.monthly-voucher-details ul,
.monthly-voucher-instructions ol {
  padding-left: 1.5rem;
}

.monthly-voucher-details li,
.monthly-voucher-instructions li {
  margin-bottom: 0.5rem;
  color: #555;
}

@media (max-width: 768px) {
  .monthly-voucher-popup-content {
    padding: 1.5rem;
    width: 95%;
  }
}

.loader {
  width: 20px;
  height: 20px;
  border: 2px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.generate-monthly-voucher-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.home-estatisticas .info-button,
.home-estatisticas .generate-voucher-button {
  margin-top: 2rem;
}