.whatsappProcessorMain {
    min-height: 100vh;
    background: linear-gradient(150deg, #f5fff0 0%, #cfd5aa 100%);
  }
  
  .whatsappProcessorContainer {
    max-width: 900px;
    margin: 0 auto;
    padding: 2rem 24px;
  }
  
  .whatsappProcessorTitle {
    font-size: 1.875rem;
    color: #223a13;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  
  .whatsappProcessorDescription {
    font-size: 1rem;
    color: #495057;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .processorPanel {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }
  
  .textarea {
    width: 100%;
    min-height: 200px;
    padding: 1rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
    margin-bottom: 1rem;
    resize: vertical;
  }
  
  .textarea:focus {
    outline: none;
    border-color: #68b43a;
    box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.2);
  }
  
  .processButton {
    background-color: #68b43a;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0 auto;
  }
  
  .processButton:hover:not(:disabled) {
    background-color: #5a9d32;
    transform: translateY(-2px);
  }
  
  .processButton:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
  }
  
  .resultsPanel {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .resultsTitle {
    font-size: 1.5rem;
    color: #223a13;
    margin-bottom: 1.5rem;
    font-weight: 700;
  }
  
  .resultsGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  .resultSection h3 {
    font-size: 1.25rem;
    color: #223a13;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .resultList {
    background-color: #f8f9fa;
    border-radius: 4px;
    padding: 1rem;
  }
  
  .resultItem {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    gap: 1rem;
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6;
  }
  
  .resultItem:last-child {
    border-bottom: none;
  }
  
  .phone {
    font-weight: 600;
    color: #495057;
  }
  
  .status {
    padding: 0.25rem 0.5rem;
    border-radius: 16px;
    font-size: 0.875rem;
    text-align: center;
  }
  
  .status.added {
    background-color: #d4edda;
    color: #155724;
  }
  
  .status.removed {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .username {
    color: #6c757d;
  }
  
  .errorReason {
    color: #dc3545;
    font-size: 0.875rem;
  }
  
  .successIcon {
    color: #68b43a;
  }
  
  .errorIcon {
    color: #dc3545;
  }
  
  @media screen and (max-width: 768px) {
    .whatsappProcessorContainer {
      padding: 1rem;
    }
  
    .whatsappProcessorTitle {
      font-size: 1.5rem;
    }
  
    .resultItem {
      grid-template-columns: 1fr;
      gap: 0.5rem;
    }
  }