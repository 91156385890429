:root {
  --color-primary: #68b43a;
  --color-primary-light: #7fc954;
  --color-primary-dark: #4e8c2a;
  --color-secondary: #223a13;
  --color-background: #f5fff0;
  --color-text: #333;
  --color-text-light: #666;
  --color-white: #fff;
  --color-gray-100: #f8f9fa;
  --color-gray-200: #e9ecef;
  --color-gray-300: #dee2e6;
  --color-gray-400: #ced4da;
  --color-gray-500: #adb5bd;
  --color-success: #28a745;
  --color-danger: #dc3545;
  --border-radius-sm: 4px;
  --border-radius: 8px;
  --border-radius-lg: 16px;
  --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  --box-shadow-hover: 0 4px 12px rgba(0, 0, 0, 0.12);
  --transition: 0.2s ease-in-out;
}

.salesAdmin {
  min-height: 100vh;
  background: linear-gradient(150deg, var(--color-background) 0%, #cfd5aa 100%);
  font-family: 'Roboto', sans-serif;
}

.container {
  max-width: 1248px;
  margin: 0 auto;
  padding: clamp(1rem, 5vw, 2rem);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: clamp(1.5rem, 4vw, 2rem);
  flex-wrap: wrap;
  gap: 1rem;
}

.title {
  font-size: clamp(1.5rem, 5vw, 1.875rem);
  color: var(--color-secondary);
}

.filterToggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: var(--color-primary);
  border: none;
  border-radius: var(--border-radius);
  font-size: clamp(0.875rem, 2vw, 1rem);
  color: var(--color-white);
  cursor: pointer;
  transition: all var(--transition);
}

.filterToggle:hover {
  background-color: var(--color-primary-dark);
}

.filters {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  padding: clamp(1rem, 3vw, 1.5rem);
  margin-bottom: 1.5rem;
  box-shadow: var(--box-shadow);
}

.filterGroup {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  position: relative;
}

.searchInputWrapper,
.dateInputWrapper {
  position: relative;
  flex: 1;
  min-width: 200px;
}

.searchIcon,
.calendarIcon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-gray-400);
  font-size: clamp(1rem, 3vw, 1.25rem);
  pointer-events: none;
}

.searchInput,
.dateInput,
.input,
.select {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border: 1px solid var(--color-gray-300);
  border-radius: var(--border-radius);
  font-size: clamp(0.875rem, 2vw, 1rem);
  color: var(--color-text);
  transition: all var(--transition);
}

.searchInput:focus,
.dateInput:focus,
.input:focus,
.select:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.2);
}

.filterButton {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: clamp(0.875rem, 2vw, 1rem);
  transition: background-color var(--transition);
}

.filterButton:hover {
  background-color: var(--color-primary-dark);
}

.userInfo {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  padding: clamp(1rem, 3vw, 1.5rem);
  margin-bottom: 1.5rem;
  box-shadow: var(--box-shadow);
}

.userInfo h3 {
  margin-top: 0;
  color: var(--color-secondary);
  font-size: clamp(1.125rem, 3vw, 1.25rem);
  margin-bottom: 1rem;
}

.userInfo p {
  margin: 0.5rem 0;
  color: var(--color-text);
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.totals {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.totals p {
  font-size: clamp(1rem, 2.5vw, 1.125rem);
  color: var(--color-text);
}

.totalAmount {
  font-weight: 700;
  color: var(--color-primary);
}

.payAllButton {
  width: 100%;
  background-color: #17a2b8;
  color: var(--color-white);
  border: none;
  padding: 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: clamp(1rem, 2.5vw, 1.125rem);
  font-weight: 600;
  transition: background-color var(--transition);
  margin-bottom: 1.5rem;
}

.payAllButton:hover {
  background-color: #138496;
}

.content {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  padding: clamp(1rem, 3vw, 2rem);
  box-shadow: var(--box-shadow);
}

.tableWrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table th,
.table td {
  padding: clamp(0.5rem, 2vw, 1rem);
  text-align: left;
  border-bottom: 1px solid var(--color-gray-200);
}

.table th {
  background-color: var(--color-gray-100);
  font-weight: 600;
  color: var(--color-text-light);
  position: sticky;
  top: 0;
  z-index: 10;
  font-size: clamp(0.75rem, 2vw, 0.875rem);
}

.table tr:last-child td {
  border-bottom: none;
}

.status {
  padding: 0.25rem 0.5rem;
  border-radius: var(--border-radius-sm);
  font-size: clamp(0.6875rem, 1.5vw, 0.75rem);
  font-weight: 600;
  text-transform: uppercase;
}

.status.paid {
  background-color: var(--color-success);
  color: var(--color-white);
}

.status.pending {
  background-color: var(--color-danger);
  color: var(--color-white);
}

.status.canceled {
  background-color: var(--color-gray-500);
  color: var(--color-white);
}

.actionButton {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-primary);
  font-size: clamp(1rem, 3vw, 1.25rem);
  transition: color var(--transition);
  padding: 0.25rem;
}

.actionButton:hover {
  color: var(--color-primary-dark);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.paginationButton {
  padding: clamp(0.25rem, 1vw, 0.5rem) clamp(0.5rem, 1.5vw, 0.75rem);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: var(--border-radius);
  color: var(--color-text);
  cursor: pointer;
  transition: all var(--transition);
  font-size: clamp(0.75rem, 2vw, 1rem);
}

.paginationButton:hover {
  background-color: var(--color-gray-100);
}

.paginationButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.popup {
  background-color: var(--color-white);
  padding: clamp(1.5rem, 4vw, 2rem);
  border-radius: var(--border-radius-lg);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  animation: popupFadeIn 0.3s ease-out;
}

.popup h2 {
  margin-top: 0;
  color: var(--color-secondary);
  font-size: clamp(1.25rem, 3vw, 1.5rem);
  margin-bottom: 1rem;
  padding-right: 2rem;
}

.popup p {
  margin: 0.75rem 0;
  font-size: clamp(0.875rem, 2vw, 1rem);
  line-height: 1.6;
  color: var(--color-text);
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: var(--color-text-light);
  font-size: 1.5rem;
  cursor: pointer;
  transition: color var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

.closeButton:hover {
  color: var(--color-text);
  background-color: var(--color-gray-100);
}

.popupContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.popupInfo,
.popupProducts {
  background-color: var(--color-gray-100);
  padding: 1rem;
  border-radius: var(--border-radius);
}

.popupInfo h3,
.popupProducts h3 {
  margin-top: 0;
  font-size: clamp(1rem, 2.5vw, 1.25rem);
  color: var(--color-secondary);
  margin-bottom: 0.75rem;
}

.popupProducts ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.popupProducts li {
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--color-gray-200);
}

.popupProducts li:last-child {
  border-bottom: none;
}

@keyframes popupFadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@media (max-width: 768px) {
  .popupContent {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .filterGroup {
    flex-direction: column;
  }

  .searchInputWrapper,
  .dateInputWrapper,
  .input,
  .select {
    width: 100%;
  }

  .table {
    display: block;
  }

  .table thead {
    display: none;
  }

  .table tbody,
  .table tr,
  .table td {
    display: block;
  }

  .table tr {
    margin-bottom: 1rem;
    border: 1px solid var(--color-gray-300);
    border-radius: var(--border-radius);
    padding: 1rem;
  }

  .table td {
    border-bottom: none;
    padding: 0.5rem 0;
  }

  .table td::before {
    content: attr(data-label);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--color-text-light);
    display: block;
    margin-bottom: 0.25rem;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInFromBottom {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.content {
  animation: fadeIn 0.3s ease-out;
}

.table tr {
  animation: slideInFromBottom 0.3s ease-out;
  animation-fill-mode: both;
}

.table tr:nth-child(1) { animation-delay: 0.05s; }
.table tr:nth-child(2) { animation-delay: 0.1s; }
.table tr:nth-child(3) { animation-delay: 0.15s; }
.table tr:nth-child(4) { animation-delay: 0.2s; }
.table tr:nth-child(5) { animation-delay: 0.25s; }

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: #1a1a1a;
    --color-text: #f0f0f0;
    --color-text-light: #a0a0a0;
    --color-white: #2a2a2a;
    --color-gray-100: #333333;
    --color-gray-200: #404040;
    --color-gray-300: #4d4d4d;
    --color-gray-400: #666666;
    --color-gray-500: #808080;
  }

  .salesAdmin {
    background: linear-gradient(150deg, var(--color-background) 0%, #2a3a1a 100%);
  }

  .filters,
  .userInfo,
  .content,
  .popup {
    background-color: var(--color-gray-100);
  }

  .table th {
    background-color: var(--color-gray-200);
  }

  .searchInput,
  .dateInput,
  .input,
  .select,
  .paginationButton {
    background-color: var(--color-gray-100);
    color: var(--color-text);
    border-color: var(--color-gray-300);
  }
}

.searchResults {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-top: none;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
  display: block;
}

.searchResults:empty {
  display: none;
}

.searchResults::-webkit-scrollbar {
  width: 8px;
}

.searchResults::-webkit-scrollbar-track {
  background: var(--color-gray-200);
}

.searchResults::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-400);
  border-radius: 20px;
  border: 2px solid var(--color-gray-200);
}

.searchResults li {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.searchResults li:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-200);
}

.searchResults li:hover {
  background-color: var(--color-primary-light);
  color: var(--color-white);
}

.searchResults li::before {
  content: '\f007';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: var(--color-gray-500);
  transition: color 0.2s ease;
}

.searchResults li:hover::before {
  color: var(--color-white);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.searchResults li {
  animation: fadeInUp 0.3s ease forwards;
  opacity: 0;
}

.searchResults li:nth-child(1) { animation-delay: 0.05s; }
.searchResults li:nth-child(2) { animation-delay: 0.1s; }
.searchResults li:nth-child(3) { animation-delay: 0.15s; }
.searchResults li:nth-child(4) { animation-delay: 0.2s; }
.searchResults li:nth-child(5) { animation-delay: 0.25s; }

@media (max-width: 768px) {
  .searchResults {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 50vh;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    border: none;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  }

  .searchResults::before {
    content: '';
    display: block;
    width: 40px;
    height: 4px;
    background-color: var(--color-gray-400);
    border-radius: 2px;
    margin: 0.5rem auto;
  }
}

@media (prefers-reduced-motion: reduce) {
  .searchResults li {
    animation: none;
  }
}

.exportButton {
  background-color: rgba(91, 93, 89, 1);
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.exportButton:hover {
  background-color: #45a049;
}