:root {
  --color-primary: #68B43A;
  --color-text-primary: #060A03;
  --color-text-secondary: #929491;
  --color-background: #ffffff;
  --color-border: #e0e0e0;
  --color-input-background: #ebebeb;
  --max-width: 1248px;
  --font-family: "Inter", sans-serif;
}

.post-comments {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--color-background);
  font-family: var(--font-family);
}

.post-comments__header,
.post-comments__content,
.post-comments__current-user-reply-content {
  max-width: var(--max-width);
  width: 100%;
  margin: 0 auto;
  padding: 32px 24px;
}

.post-comments__header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.post-comments__back-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.post-comments__back-icon {
  fill: var(--color-text-secondary);
  width: 24px;
  height: 24px;
}

.post-comments__title {
  color: var(--color-text-primary);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.post-comments__content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 116px;
}

.post-comments__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
}

.post-comments__count,
.post-comments__filter-text {
  color: var(--color-text-secondary);
  font-size: 16px;
  line-height: 24px;
}

.post-comments__filter {
  display: flex;
  align-items: center;
  gap: 8px;
}

.post-comments__filter-icon {
  fill: var(--color-text-secondary);
  width: 24px;
  height: 24px;
}

.post-comments__list {
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding-top: 12px;
  padding-bottom: 90px;
}

.post-comments__comment,
.post-comments__reply {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.post-comments__comment-main,
.post-comments__reply {
  display: flex;
  gap: 8px;
}

.post-comments__avatar,
.post-comments__reply-avatar,
.post-comments__avatar-initials,
.post-comments__reply-avatar-initials {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}

.post-comments__avatar-initials,
.post-comments__reply-avatar-initials {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-border);
  color: var(--color-text-primary);
  font-size: 18px;
  font-weight: bold;
}

.post-comments__comment-content,
.post-comments__reply-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex-grow: 1;
}

.post-comments__comment-header,
.post-comments__reply-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
}

.post-comments__comment-author-timestamp,
.post-comments__reply-author-timestamp {
  display: flex;
  gap: 12px;
  align-items: center;
  padding-top: 8px;
}

.post-comments__comment-author,
.post-comments__reply-author {
  color: var(--color-text-primary);
  font-size: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 24px;
}

.post-comments__comment-timestamp,
.post-comments__reply-timestamp {
  color: var(--color-text-secondary);
  font-size: 16px;
  line-height: 24px;
}

.post-comments__comment-text,
.post-comments__reply-text {
  color: var(--color-text-primary);
  font-size: 17px;
  line-height: 24px;
}

.post-comments__comment-actions,
.post-comments__reply-actions {
  display: flex;
  gap: 20px;
  align-items: center;
  padding-top: 8px;
}

.post-comments__like-button,
.post-comments__reply-button,
.post-comments__delete-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  cursor: pointer;
}

.post-comments__like-icon,
.post-comments__reply-icon,
.post-comments__delete-icon {
  fill: #d2d2d2;
  width: 24px;
  height: 24px;
}

.post-comments__like-count,
.post-comments__reply-button span,
.post-comments__delete-button span {
  color: var(--color-text-secondary);
  font-size: 16px;
  line-height: 24px;
}

.post-comments__delete-button:hover .post-comments__delete-icon {
  fill: #dc3545;
  transform: scale(1.1);
}

.post-comments__reply-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.post-comments__reply-textarea {
  width: 100%;
  resize: none;
  padding: 8px 16px;
  border: none;
  background-color: var(--color-input-background);
  color: var(--color-text-primary);
  font-family: var(--font-family);
  outline: none;
  line-height: 24px;
}

.post-comments__send-button {
  align-self: flex-end;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--color-primary);
  color: var(--color-background);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  border: none;
}

.post-comments__replies {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 52px;
}

.post-comments__current-user-reply {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--color-background);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.post-comments__current-user-reply-content {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px 24px;
}

.post-comments__current-user-reply-avatar {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  object-fit: cover;
}

.post-comments__current-user-reply-input {
  flex-grow: 1;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--color-input-background);
  color: var(--color-text-primary);
  font-family: var(--font-family);
  line-height: 24px;
  border: none;
}

.post-comments__current-user-reply-button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--color-primary);
  cursor: pointer;
  border: none;
}

.post-comments__current-user-reply-icon {
  fill: var(--color-background);
  width: 20px;
  height: 20px;
}

.post-comments__filter-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.post-comments__filter-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--color-background);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  display: none;
}

.post-comments__filter-dropdown:hover .post-comments__filter-options {
  display: block;
}

.post-comments__filter-option {
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-align: left;
  width: 100%;
  padding: 12px 16px;
  color: var(--color-text-primary);
}

.post-comments__filter-option:hover,
.post-comments__filter-option--active {
  background-color: var(--color-input-background);
}

.post-comments__comment--reply {
  margin-left: 20px;
  border-left: 2px solid var(--color-border);
  padding-left: 20px;
}

.post-comments__reply-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: var(--color-text-secondary);
  font-size: 14px;
}

.post-comments__reply-indicator-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  fill: var(--color-text-secondary);
}

.post-comments__reply-indicator-text {
  font-style: italic;
}

@media (max-width: 1015px) {
  .post-comments__content {
    padding-bottom: 116px;
  }
}

@media (max-width: 480px) {
  .post-comments__header,
  .post-comments__content,
  .post-comments__current-user-reply-content {
    padding: 16px;
  }

  .post-comments__reply-button span {
    display: none;
  }

  .post-comments__reply-textarea,
  .post-comments__send-button {
    font-size: 14px;
  }

  .post-comments__send-button {
    padding: 6px 12px;
  }

  .post-comments__comment-actions {
    gap: 8px;
  }

  .post-comments__title {
    font-size: 19px;
    line-height: 1.4;
  }

  .post-comments__avatar-initials,
  .post-comments__reply-avatar-initials {
    font-size: 15px;
    width: 36px;
    height: 36px;
  }

  .post-comments__comment-timestamp,
  .post-comments__reply-timestamp,
  .post-comments__filter-text,
  .post-comments__count {
    font-size: 14px;
    line-height: 1.4;
  }

  .post-comments__comment-author,
  .post-comments__reply-author {
    font-size: 16px;
    line-height: 1.4;
  }

  .post-comments__back-icon,
  .post-comments__like-icon,
  .post-comments__reply-icon,
  .post-comments__delete-icon {
    width: 20px;
    height: 20px;
  }

  .post-comments__delete-button span {
    font-size: 14px;
  }

  .post-comments__comment--reply {
    margin-left: 10px;
    padding-left: 10px;
  }

  .post-comments__reply-indicator {
    font-size: 12px;
  }

  .post-comments__reply-indicator-icon {
    width: 14px;
    height: 14px;
  }
}

@media screen and (max-width: 370px) {
  .post-comments__avatar-initials,
  .post-comments__current-user-reply-avatar {
    display: none;
  }
}
