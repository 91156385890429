.quizzPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(150deg, #f5fff0, #cfd5aa);
}

.quizContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  backdrop-filter: blur(10px);
  border-radius: 24px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 90%;
  width: 600px;
  position: relative;
  margin: 40px auto;
}

.quizHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
}

.quizTitle {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.quizTimer {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.quizTimer svg {
  margin-right: 8px;
  color: #68b43a;
}

.quizProgress {
  width: 100%;
  height: 15px;
  background-color: rgba(240, 240, 240, 0.7);
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quizProgressBar {
  height: 100%;
  background: linear-gradient(90deg, #68b43a 0%, #9dff4d 100%);
  transition: width 0.3s ease;
}

.questionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.questionHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.questionTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

.questionImage {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.questionText {
  font-size: 1.2rem;
  color: #555555;
  text-align: center;
  margin-bottom: 40px;
}

.answerList {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.answerItem {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  font-size: 1.1rem;
  color: #333333;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.answerItem:hover {
  background-color: rgba(255, 255, 255, 0.9);
  transform: translateY(-5px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.answerItem.selectedAnswer {
  background-color: #68b43a;
  color: #ffffff;
}

.nextButton {
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #68b43a;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nextButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  box-shadow: none;
}

.nextButton.nextButtonActive {
  background-color: #68b43a;
}

.nextButton:hover {
  background-color: #5a9c2e;
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.quizCompletionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.quizCompletionTitle {
  font-size: 45px;
  font-weight: bold;
  color: #878787;
  margin-bottom: 20px;
}

.quizCompletionText {
  font-size: 1.2rem;
  color: #555555;
  margin-bottom: 40px;
}

.quizReward {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #333333;
  margin-bottom: 40px;
}

.rewardPoints {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #17a2b8;
}

.rewardPoints img {
  width: 50px;
  height: 50px;
  margin-left: 4px;
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #f44336;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.closeButton:hover {
  background-color: #d32f2f;
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.closeButton svg {
  margin-right: 8px;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  font-size: 1.2rem;
  color: #555555;
}

.spinner {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 6px solid #f0f0f0;
  border-top-color: #68b43a;
  animation: spin 1s infinite linear;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.errorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  font-size: 1.2rem;
  color: #f44336;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .quizContainer {
    padding: 20px;
    margin: 20px;
    border-radius: 16px;
  }

  .quizTitle {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .quizTimer {
    font-size: 1.2rem;
    padding: 8px 16px;
  }

  .questionTitle {
    font-size: 1.2rem;
  }

  .questionText {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .answerItem {
    padding: 16px;
    font-size: 1rem;
    margin-bottom: 16px;
  }

  .nextButton {
    padding: 12px 24px;
    font-size: 1rem;
    margin-top: 30px;
  }

  .quizCompletionTitle {
    font-size: 30px;
    margin-bottom: 16px;
  }

  .quizCompletionText {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .quizReward {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .rewardPoints {
    font-size: 1.2rem;
  }

  .closeButton {
    padding: 12px 24px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .quizContainer {
    padding: 16px;
    margin: 16px;
    border-radius: 12px;
  }

  .quizTitle {
    font-size: 20px;
  }

  .quizTimer {
    font-size: 1rem;
    padding: 6px 12px;
  }

  .questionTitle {
    font-size: 1rem;
  }

  .questionText {
    font-size: 0.9rem;
    margin-bottom: 24px;
  }

  .answerItem {
    padding: 12px;
    font-size: 0.9rem;
    margin-bottom: 12px;
  }

  .nextButton {
    padding: 10px 20px;
    font-size: 0.9rem;
    margin-top: 24px;
  }

  .quizCompletionTitle {
    font-size: 24px;
    margin-bottom: 12px;
  }

  .quizCompletionText {
    font-size: 0.9rem;
    margin-bottom: 24px;
  }

  .quizReward {
    font-size: 0.9rem;
    margin-bottom: 24px;
  }

  .rewardPoints {
    font-size: 1rem;
  }

  .rewardPoints img {
    width: 40px;
    height: 40px;
  }

  .closeButton {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}