.avaliar-app {
    width: 100%;
    display: flex;
    overflow: auto;
    background: linear-gradient(150deg, rgba(245, 255, 240, 1) 0%, rgba(207, 213, 170, 1) 100%);
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  
  .avaliar-app__header {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-start;
    background-color: #ffffff;
  }
  
  .avaliar-app__content {
    gap: 32px;
    width: 100%;
    height: auto;
    display: flex;
    padding: 32px 16px;
    max-width: 1500px;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .avaliar-app__column {
    gap: 8px;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .avaliar-app__title {
    color: var(--dl-color-default-black);
    height: auto;
    align-self: stretch;
    text-align: center;
    line-height: 32px;
    font-size: 30px;
    color: #223a13;
    font-weight: 600;
  }
  
  .avaliar-app__subtitle {
    color: rgb(106, 108, 104);
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    align-self: center;
    font-style: normal;
    text-align: center;
    font-family: "Inter";
    font-weight: 400;
    line-height: 24px;
    font-stretch: normal;
    letter-spacing: 0em;
  }
  
  .avaliar-app__card {
    gap: 40px;
    width: 100%;
    display: flex;
    padding: 24px;
    overflow: hidden;
    max-width: 900px;
    align-self: center;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.03);
    align-items: flex-start;
    border-radius: 8px;
    flex-direction: column;
    background-color: var(--dl-color-default-white);
  }
  
  .avaliar-app__rating-options {
    gap: 8px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 500px;
    align-self: center;
    min-height: 88px;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-around;
  }
  
  .avaliar-app__rating-emoji {
    width: 80px;
    padding: 16px;
    object-fit: cover;
    transition: 0.3s;
    border-radius: 100px;
    padding-bottom: 16px;
    background-color: rgba(207, 213, 170, 0.25);
    cursor: pointer;
  }
  
  .avaliar-app__rating-emoji--selected {
    width: 88px;
    padding: 12px;
    background-color: rgba(207, 213, 170, 0.6);
  }
  
  .avaliar-app__input-container {
    gap: 24px;
    width: 100%;
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
  }
  
  .avaliar-app__comment-textarea {
    color: #060a03;
    width: 100%;
    resize: none;
    font-family: "Inter";
    line-height: 24px;
    padding-top: 8px;
    border-width: 0px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    background-color: #ebebeb;
    outline: none;
  }
  
  .avaliar-app__submit-button {
    gap: 8px;
    width: 100%;
    display: flex;
    padding: 8px 16px;
    overflow: hidden;
    max-width: 220px;
    align-self: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    justify-content: center;
    background-color: var(--dl-color-primary-primary500);
    cursor: pointer;
  }
  
  .avaliar-app__submit-button span {
    color: #fff;
    height: auto;
    flex-grow: 1;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-family: "Inter";
    font-weight: 500;
    line-height: 24px;
    font-stretch: normal;
    letter-spacing: 0em;
  }

  @media (max-width: 546px) {
    .avaliar-app__rating-options {
      justify-content: center;
      min-height: fit-content;
    }

    .avaliar-app__card {
      gap: 24px;
    }

    .avaliar-app__rating-emoji {
      width: 40px;
      height: 40px;
      padding: 8px;
    }

    .avaliar-app__rating-emoji--selected {
      width: 50px;
      height: 50px;
    }

    .avaliar-app__subtitle {
      font-size: 14px;
      line-height: 1.5;
    }

    .avaliar-app__title {
      font-size: 24px;
    }

    .avaliar-app__comment-textarea, .avaliar-app__submit-button span {
      font-size: 14px;
    }

    .avaliar-app__submit-button {
      width: 100%;
      max-width: 100%;
    }
  }