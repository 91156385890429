:root {
  --primary-color: #6c757d;
  --secondary-color: #17a2b8;
  --accent-color: #68b43a;
  --background-color: #f5fff0;
  --card-background-color: #ffffff;
  --text-color: #333333;
  --inactive-color: #999999;
  --border-radius: 12px;
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.academyDashboard {
  background: linear-gradient(150deg, #f5fff0, #cfd5aa);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
}

.dashboardContent {
  width: 100%;
  padding: 40px 16px;
  margin: 0 auto;
}

.dashboardHeader {
  margin-bottom: 2rem;
}

.academyDashboard h1 {
  font-size: 30px;
  color: #223a13;
  margin: 0;
}

.descriptionContainer {
  display: flex;
  align-items: center;
  background-color: #e8f5e9;
  border-radius: var(--border-radius);
  padding: 10px 20px;
  margin-top: 16px;
  box-shadow: var(--box-shadow);
}

.descriptionIcon {
  margin-right: 10px;
  color: var(--accent-color);
}

.description {
  font-size: 1rem;
  color: #555;
  margin: 0;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 16px;
}

.userName {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);
}

.userPoints {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var (--text-color);
}

.userPoints svg {
  margin-left: 4px;
}

.coursesGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
}

.courseCard {
  background-color: var(--card-background-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease;
  position: relative;
  flex: 1 1 300px; /* Alterado para flex-grow, flex-shrink, flex-basis */
  min-width: 300px; /* Definida uma largura mínima */
  max-width: 400px; /* Limite da largura máxima */
}

.courseCard:hover {
  transform: translateY(-5px);
}

.courseCardImage {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.courseCardContent {
  padding: 24px;
}

.courseTitleH3 {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 8px;
}

.courseCategory {
  font-size: 1rem;
  color: var(--inactive-color);
  margin-bottom: 12px;
}

.courseProgressContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background-color: #e0e0e0;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  height: 20px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.courseProgressBar {
  height: 100%;
  background: linear-gradient(90deg, #68b43a 0%, #9dff4d 100%);
  transition: width 1s ease-in-out;
}

.courseProgressLabel {
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--text-color);
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.courseProgressBar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.courseProgressContainer:hover .courseProgressBar::after {
  opacity: 1;
}

.courseProgressBar {
  animation: progressAnimation 2s infinite;
}

@keyframes progressAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.courseActions {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.actionButton {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: bold;
  color: var(--card-background-color);
  background-color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.actionButton.quizButton {
  background-color: var(--secondary-color);
  color: var(--card-background-color);
  border: none;
  border-radius: var(--border-radius);
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionButton.quizButton:hover {
  background-color: #138496;
  transform: translateY(-3px);
}

.actionButton:hover {
  background-color: #495057;
}

.actionButton svg {
  margin-right: 8px;
}

.startCourseButton {
  background-color: var(--accent-color);
}

.startCourseButton:hover {
  background-color: #5a9c2e;
}

.loadingSpinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 20px;
  color: var(--text-color);
}

.spinner {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid var(--inactive-color);
  border-top-color: var(--accent-color);
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.errorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 20px;
  color: var(--text-color);
  text-align: center;
}

.noCoursesMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  font-size: 20px;
  color: var(--text-color);
  text-align: center;
}

@media (max-width: 768px) {
  .coursesGrid {
    flex-direction: column; /* Alterado para colunas */
    align-items: center; /* Centralizar em viewports pequenos */
  }
  .userInfo {
    flex-direction: column;
    align-items: flex-start;
  }
}

.courseCardCompleted {
  border: none;
  position: relative;
}

.quizMessage {
  font-size: 1rem;
  color: var(--text-color);
  margin-top: 16px;
  text-align: center;
  padding: 16px;
  background-color: #f0fff4;
  border-radius: var(--border-radius);
}

.quizMessage p {
  margin: 0;
  font-weight: normal;
  color: var(--accent-color);
  font-size: 1.1rem;
  line-height: 1.5;
}

.quizMessage span {
  font-weight: bold;
}

.courseDescription {
  font-size: 0.9rem;
  color: #555;
  margin-top: 10px;
}

.highlight {
  font-weight: bold;
  color: var(--accent-color);
}

.pointsIcon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  vertical-align: middle;
}

.quizzPoints {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.completedSeal {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--accent-color);
  color: #ffffff;
  padding: 5px 10px;
  border-radius: var(--border-radius);
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 5px;
}

.completedSeal::before {
  content: '✓';
  font-size: 1rem;
}

.courseCardCompleted .courseProgressBar {
  background: linear-gradient(90deg, rgba(104, 180, 58, 0.8) 0%, rgba(157, 255, 77, 0.8) 100%);
}

.courseCardCompleted .quizMessage {
  background-color: rgba(240, 255, 244, 0.7);
  color: var(--accent-color);
}

.quizCompletedMessage {
  margin-top: 16px;
  background-color: rgba(23, 162, 184, 0.1);
  border: 1px solid var(--secondary-color);
  border-radius: var(--border-radius);
  padding: 10px;
  text-align: center;
  color: var(--secondary-color);
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  font-weight: bold;
}

.quizCompletedIcon {
  color: var(--secondary-color);
  font-size: 4rem;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modalContent {
  background-color: var(--card-background-color);
  border-radius: var(--border-radius);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  width: 90%;
  max-width: 500px;
  position: relative;
  animation: modalAppear 0.3s ease-out;
}

@keyframes modalAppear {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--inactive-color);
  cursor: pointer;
  transition: color 0.3s ease;
}

.closeButton:hover {
  color: var(--text-color);
}

.modalTitle {
  font-size: 1.8rem;
  color: var(--accent-color);
  margin-bottom: 1.5rem;
  text-align: center;
}

.quizInfo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  background-color: #f0f8ff;
  border-radius: var(--border-radius);
  padding: 1rem;
}

.quizInfoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quizInfoLabel {
  font-size: 0.9rem;
  color: var(--inactive-color);
  margin-bottom: 0.3rem;
}

.quizInfoValue {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);
}

.quizDescription {
  font-size: 1rem;
  color: var(--text-color);
  line-height: 1.6;
  margin-bottom: 1.5rem;
  text-align: center;
}

.modalActions {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.cancelButton,
.startQuizButton {
  flex: 1;
  padding: 0.8rem;
  border: none;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancelButton {
  background-color: #f0f0f0;
  color: var(--text-color);
}

.cancelButton:hover {
  background-color: #e0e0e0;
}

.startQuizButton {
  background-color: var(--accent-color);
  color: white;
}

.startQuizButton:hover {
  background-color: #5a9c2e;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.startQuizButton:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .modalContent {
    padding: 1.5rem;
  }

  .quizInfo {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .modalActions {
    flex-direction: column;
  }
}