.lp-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  padding-bottom: 32px;
  background-image: url("/imgfundolp-1500w.webp");
}
.lp-container1 {
  gap: 40px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 660px;
  align-items: center;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  padding-bottom: 32px;
}
.lp-logo {
  width: 194px;
  height: 92px;
  object-fit: cover;
}
.lp-video {
  width: 100%;
  height: auto;
  border-radius: var(--dl-radius-radius-radius8);
}
.lp-visualizacoes-stories {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-primary-primary50);
}
.lp-text {
  color: var(--dl-color-default-black);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
}
.lp-resposta {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.lp-label {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.lp-textinput {
  color: #5b5d59;
}
.lp-visualizacoes-reels {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-primary-primary50);
}
.lp-text03 {
  color: var(--dl-color-default-black);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
}
.lp-resposta1 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.lp-label1 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.lp-textinput1 {
  color: #5b5d59;
}
.lp-influencia-stories {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-primary-primary50);
}
.lp-text05 {
  color: var(--dl-color-default-black);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Inter";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
}
.lp-opcoes {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.lp-opcao1 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.lp-selected {
  width: 24px;
  height: 24px;
}
.lp-none {
  width: 24px;
  height: 24px;
}
.lp-text06 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: left;
  line-height: 24px;
}
.lp-opcao2 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.lp-selected1 {
  width: 24px;
  height: 24px;
}
.lp-none1 {
  width: 24px;
  height: 24px;
}
.lp-text07 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: left;
  line-height: 24px;
}
.lp-opcao3 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.lp-selected2 {
  width: 24px;
  height: 24px;
}
.lp-none2 {
  width: 24px;
  height: 24px;
}
.lp-text08 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: left;
  line-height: 24px;
}
.lp-recomendar-equipe {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-primary-primary50);
}
.lp-text09 {
  color: var(--dl-color-default-black);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Inter";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
}
.lp-resposta2 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.lp-label2 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.lp-textinput2 {
  color: #5b5d59;
}
.lp-qntd-midia-mes {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-primary-primary50);
}
.lp-text11 {
  color: var(--dl-color-default-black);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Inter";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
}
.lp-resposta3 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.lp-label3 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.lp-textinput3 {
  color: #5b5d59;
}
.lp-contato {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-primary-primary50);
}
.lp-text13 {
  color: var(--dl-color-default-black);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: "Inter";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
}
.lp-opcoes1 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.lp-opcao-sim {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.lp-selected3 {
  width: 24px;
  height: 24px;
}
.lp-none3 {
  width: 24px;
  height: 24px;
}
.lp-text14 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: left;
  line-height: 24px;
}
.lp-telefone {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.lp-label4 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.lp-textinput4 {
  color: #5b5d59;
}
.lp-supporting-text {
  color: var(--dl-color-gray-g300);
  height: auto;
  font-size: 12px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Inter;
  font-weight: 400px;
  line-height: 14px;
  font-stretch: normal;
}
.lp-opcao-nao {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.lp-selected4 {
  width: 24px;
  height: 24px;
}
.lp-none4 {
  width: 24px;
  height: 24px;
}
.lp-text16 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: left;
  line-height: 24px;
}
.lp-resposta4 {
  gap: 24px;
  width: 100%;
  display: flex;
  padding: 24px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  background-color: var(--dl-color-primary-primary800);
}
.lp-text17 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 18px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
}
.lp-text19 {
  color: var(--dl-color-default-white);
  height: auto;
  font-size: 16px;
  align-self: stretch;
  font-style: normal;
  text-align: left;
  font-family: Inter;
  font-weight: 400px;
  line-height: 24px;
  font-stretch: normal;
}
.lp-entrar-no-time {
  gap: 24px;
  display: flex;
  padding: 24px;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
}
.lp-text21 {
  color: var(--dl-color-default-black);
  flex-grow: 1;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-family: "Inter";
  font-weight: 400px;
  line-height: 24px;
  font-stretch: normal;
}
.lp-botao-entrar {
  gap: 8px;
  width: 169px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  background-color: var(--dl-color-primary-primary500);
}
.lp-text25 {
  color: var(--dl-color-default-black);
  flex-grow: 1;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
}

.submit-button{
  display: flex;
  justify-content: center;
  width: 100%;
}

.submit-button button {
  width: 80%;
}

.faq-header{
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-content: center;
  text-align: center;
}

.faq-header span {
  font-size: 20px;
}

.faq{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
}

.faq-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.spinner-container{
  position: absolute;
  display: flex;
  gap: 10px;
  right: 15px;
  font-size: 10px;
  color: white;
  align-items: center;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}