.ranking__container {
  width: 100%;
  max-width: 340px;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.ranking__content {
  gap: 24px;
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}

.ranking__header {
  gap: 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.ranking__icon {
  fill: #060A03;
  width: 32px;
  height: 32px;
  padding: 2px;
  align-self: center;
}

.ranking__title {
  color: var(--dl-color-default-black);
  font-size: 24px;
  align-self: center;
  font-style: Medium;
  text-align: left;
  font-family: "Inter";
  font-weight: 500;
  line-height: 32px;
  font-stretch: normal;
  text-decoration: none;
}

.ranking__filters {
  width: 100%;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: row;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746);
}

.ranking__filter {
  flex: 1;
  display: flex;
  padding: 10px;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 8px;
  justify-content: center;
  background-color: #ffffff;
  color: rgb(207, 213, 170);
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  cursor: pointer;
}

.ranking__filter--active {
  background-color: #68B43A;
  color: #ffffff;
}

.ranking__top-three {
  display: flex;
  overflow: hidden;
  position: relative;
  gap: 16px;
  align-self: stretch;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.03);
  align-items: flex-end;
  padding: 24px 28px;
  border-radius: 8px;
  justify-content: center;
  background-color: var(--dl-color-default-white);
  padding-bottom: 0;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746);
}

.ranking__top-three .ranking__name {
  white-space: unset;
  text-align: center;
}

.ranking__top-item {
  display: flex;
  width: 30%;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}

.ranking__top-item--2 {
  margin-bottom: 120px; /* levanta a posição do top1 */
}

.ranking__photo {
  display: flex;
  position: relative;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
}

.points-gif {
  height: 24px;
  width: 24px;
}

.ranking__frame {
  width: 120px;
  align-self: center;
  object-fit: cover;
}

.ranking__profile-pic {
  top: 10px;
  left: 3px;
  right: 0px;
  width: 80px;
  height: 80px;
  bottom: 0px;
  margin: auto;
  object-fit: cover;
  border-radius: 50%;
}

.ranking__acronym {
  width: 60px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: #d2d2d2;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -55%);
}

.ranking__name {
  color: var(--dl-color-default-black);
  width: 100%;
  max-width: 170px;
  font-size: 16px;
  font-style: normal;
  font-family: "Inter";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ranking__points {
  color: var(--dl-color-default-black);
  width: fit-content;
  font-size: 16px;
  font-style: normal;
  display: flex;
  text-align: center;
  font-family: "Inter";
  font-weight: 500;
  line-height: 24px;
  font-stretch: normal;
  letter-spacing: 0em;
}

.ranking__list {
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-white);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746);

}

.ranking__list-item {
  gap: 16px;
  padding-right: 16px;
  width: 100%;
  display: flex;
  align-items: center;
}

.ranking__list-item--user {
  background-color: #f5f5f5;
  border-left: 4px solid #68B43A;
}

.ranking__index {
  width: 50px;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  background-color: #d2d2d2;
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
}

.ranking__list-item--user .ranking__index {
  width:45px;
}

.ranking__list-content {
  padding: 20px 16px;
  border-bottom: 1px solid #C7C7C7;
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ranking__profile-pic--small {
  width: 40px;
  height: 40px;
  align-self: center;
  object-fit: cover;
  border-radius: 50%;
}

.ranking__acronym--small {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #d2d2d2;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
}

.ranking__details {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.ranking__list-item:last-child .ranking__list-content {
  border-bottom: 0;
}

@media (max-width: 991px) {
  .ranking__container,.ranking__container + div {
    width: 100%;
    max-width: 100%;
  }
  
  .ranking__filter {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .ranking__name {
    max-width: 100px;
  }

  .ranking__top-three {
    gap: 0px;
    min-height: 350px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .ranking__frame {
    width: 115px;
  }

  .ranking__profile-pic {
    width: 65px;
  }

  .ranking__profile-pic--small {
    width: 65px;
  }
}

@media (max-width: 546px) {
  .ranking__top-three {
      flex-direction: column;
      gap: 20px;
      padding: 24px 8px;
    }
  
  .ranking__top-item {
      width: 100%;
      margin-bottom: 0;
    }
  
   .ranking__top-three > :nth-child(1) {
      order: 2;
    }
  
   .ranking__top-three > :nth-child(2) {
      order: 1;
    }
  
   .ranking__top-three > :nth-child(3) {
      order: 3;
    }
}

