.login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.login-conectar {
  gap: 40px;
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px 16px;
  overflow: hidden;
  background: linear-gradient(150deg, rgba(245, 255, 240, 1) 0%, rgba(207, 213, 170, 1) 100%);
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gradient-primary);
}
.login-l2 {
  width: 194px;
  height: 92px;
}
.login-frame300 {
  gap: 32px;
  width: 100%;
  display: flex;
  padding: 24px;
  overflow: hidden;
  max-width: 410px;
  align-self: center;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-white);
}
.login-frame347 {
  gap: 4px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.login-text02 {
  color: var(--dl-color-gray-g200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.login-frame309 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.login-textfields {
  gap: 8px;
  height: 64px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.login-text04 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.login-textfields1 {
  gap: 8px;
  height: 86px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.login-text06 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.login-frame2 {
  gap: 8px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: 0px;
  padding-left: 16px;
  border-radius: 4px;
  padding-right: 16px;
  padding-bottom: 0px;
  background-color: var(--dl-color-gray-g30);
}
.login-textinput1 {
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;
}
.login-visibility {
  width: 24px;
  height: 24px;
  align-self: center;
}
.login-text08 {
  color: var(--dl-color-gray-g300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 14px;
  text-decoration: none;
}
.login-buttons {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: var(--dl-color-primary-primary500);
}
.login-text10 {
  color: white;
  height: auto;
  flex-grow: 1;
  text-align: center;
  line-height: 24px;
}
