.influencerCard {
    border-radius: 16px;
    padding: 20px;
    transition: all 0.3s ease;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .tiktokStyle {
    background: linear-gradient(45deg, #010101, #121212);
    color: white;
  }
  
  .instagramStyle {
    background: linear-gradient(45deg, #405DE6, #5851DB, #833AB4, #C13584, #E1306C, #FD1D1D);
    color: white;
  }
  
  .cardHeader {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .profilePicture, .profileAcronym {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 16px;
    border: 3px solid rgba(255, 255, 255, 0.3);
  }

  img.profilePicture {
    object-fit: cover;
    object-position: center;
  }
  
  .profileAcronym {
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 28px;
  }
  
  .headerInfo {
    flex-grow: 1;
  }
  
  .userName {
    font-size: 22px;
    font-weight: bold;
    margin: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .extractionDate {
    font-size: 14px;
    opacity: 0.8;
    margin: 4px 0 0;
  }
  
  .socialIcon {
    font-size: 24px;
    margin-right: 12px;
  }
  
  .tiktokStyle .socialIcon {
    color: #25F4EE;
  }
  
  .archiveButton {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 20px;
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  
  .archiveButton:hover {
    opacity: 1;
  }
  
  .engagementRate {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 8px 12px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .engagementLabel {
    font-size: 14px;
    opacity: 0.9;
  }
  
  .engagementValue {
    font-size: 18px;
    font-weight: bold;
  }
  
  .keywordsSection {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
  }
  
  .keyword, .keywordMore {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    transition: transform 0.3s ease;
  }
  
  .tiktokStyle .keyword, .tiktokStyle .keywordMore {
    background-color: rgba(37, 244, 238, 0.2);
    color: #25F4EE;
  }
  
  .keyword:hover, .keywordMore:hover {
    transform: translateY(-2px);
  }
  
  .actionSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .profileButton, .actionButton {
    padding: 10px 20px;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.3s ease;
    text-decoration: none;
  }
  
  .profileButton {
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
  }
  
  .tiktokStyle .profileButton {
    background-color: rgba(254, 44, 85, 0.8);
  }
  
  .actionButton {
    background-color: white;
    color: #333;
    padding: 10px;
    border-radius: 50%;
  }
  
  .tiktokStyle .actionButton {
    background-color: #25F4EE;
    color: #010101;
  }
  
  .profileButton:hover, .actionButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .signupLinkSection {
    margin-top: 16px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .signupLinkSection:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .signupLinkLabel {
    display: block;
    font-size: 14px;
    margin-bottom: 4px;
    opacity: 0.9;
  }
  
  .signupLink {
    color: white;
    text-decoration: none;
    word-break: break-all;
    font-size: 14px;
    opacity: 0.8;
    transition: all 0.3s ease;
  }
  
  .signupLink.copied {
    color: #25F4EE;
    font-weight: bold;
  }
  
  .tiktokStyle .signupLink.copied {
    color: #FE2C55;
  }