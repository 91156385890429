.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.popup-content h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 24px;
}

.popup-excluir-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}

.popup-excluir-message {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

.popup-excluir-description {
  font-size: 14px;
  color: #777;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.delete-button {
  color: white;
  background: #dc3545;
  transition: background 0.3s ease;
}

.delete-button:hover {
  background: #c82333;
}

.cancel-button,
.save-button,
.delete-button {
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}