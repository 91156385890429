.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}


.title {
  font-size: 28px;
  font-weight: 700;
  color: #333;
  margin-bottom: 32px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.formGroup label {
  font-size: 16px;
  font-weight: 500;
  color: #555;
}

.input,
.textarea {
  padding: 12px 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.input:focus,
.textarea:focus {
  outline: none;
  border-color: #68b43a;
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.1);
}

.textarea {
  resize: vertical;
  min-height: 100px;
}

.fileUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  border: 2px dashed #68b43a;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #f9fafb;
  position: relative;
  overflow: hidden;
}

.fileUpload:hover {
  background-color: #e8f5e9;
}

.fileInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.fileIcon {
  font-size: 48px;
  color: #68b43a;
}

.fileItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 8px 12px;
  border-radius: 4px;
  margin-top: 8px;
}

.removeFile {
  background: none;
  border: none;
  color: #dc3545;
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.removeFile:hover {
  color: #bd2130;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
}

.cancelButton,
.saveButton {
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancelButton {
  background-color: #fff;
  border: 2px solid #ced4da;
  color: #6c757d;
}

.cancelButton:hover {
  background-color: #f8f9fa;
  border-color: #6c757d;
  color: #495057;
}

.saveButton {
  background-color: #68b43a;
  border: none;
  color: #fff;
}

.saveButton:hover {
  background-color: #5a9d32;
}

@media (max-width: 640px) {
  .modal {
    padding: 24px;
    width: 95%;
  }

  .buttonGroup {
    flex-direction: column;
  }

  .cancelButton,
  .saveButton {
    width: 100%;
  }
}