.lessonPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(150deg, #f5fff0, #cfd5aa);
}

.lessonContainer {
  margin: 0 auto;
  padding: 40px 24px;
  width: 100%;
  max-width: 1248px;
}

.lessonHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.courseInfos {
  flex: 1;
}

.courseTitle {
  font-size: 2.5rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 10px;
}

.courseDescription {
  font-size: 1.2rem;
  color: #495057;
}

.courseProgress {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.progressBar {
  width: 100%;
  max-width: 200px;
  height: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  background-color: #28a745;
  border-radius: 5px;
  transition: width 0.5s ease;
}

.progressLabel {
  font-size: 0.9rem;
  color: #495057;
}

.lessonContents {
  display: flex;
  gap: 40px;
}

.lessonSidebar {
  width: 300px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 20px;
  align-self: flex-start;
}

.sidebarTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 20px;
}

.lessonList {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lessonItem {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.lessonItem:hover {
  background-color: #f8f9fa;
}

.lessonItem.completed {
  background-color: #e8f5e9;
}

.lessonItem.current {
  background-color: #e3f2fd;
  font-weight: bold;
}

.lessonNumber {
  font-size: 1.2rem;
  font-weight: bold;
  color: #212529;
  margin-right: 10px;
}

.lessonName {
  font-size: 1rem;
  color: #212529;
}

.completedIcon {
  color: #28a745;
  margin-left: auto;
}

.lessonMain {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
  flex-grow: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lessonTitle {
  font-size: 2rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 20px;
}

.lessonDescription {
  font-size: 1.1rem;
  color: #555;
  margin: 20px 0;
  line-height: 1.6;
}

.lessonFiles {
  margin-bottom: 20px;
}

.filesTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: #212529;
  margin-bottom: 10px;
}

.filesList {
  list-style: none;
  padding: 0;
}

.filesList li {
  margin-bottom: 10px;
}

.downloadLink {
  display: flex;
  align-items: center;
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
}

.downloadLink:hover {
  color: #28a745;
}

.downloadIcon {
  margin-right: 5px;
}

.lessonActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.actionButton {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  gap: 8px;
}

.actionButton:hover {
  background-color: #218838;
}

.actionButton:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.completeButton {
  background-color: #28a745;
}

.completeButton:hover {
  background-color: #218838;
}

.nextLessonButton {
  background-color: #6c757d;
}

.nextLessonButton:hover {
  background-color: #5a6268;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.5rem;
  color: #495057;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.5rem;
  color: #dc3545;
}

@media screen and (max-width: 1024px) {
  .lessonContainer {
    padding: 40px;
  }

  .lessonContents {
    flex-direction: column;
  }

  .lessonSidebar {
    width: 100%;
    position: static;
  }
}

@media screen and (max-width: 768px) {
  .lessonHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .courseProgress {
    align-self: flex-start;
    margin-top: 20px;
  }

  .lessonContainer {
    padding: 20px;
  }

  .lessonMain {
    padding: 20px;
  }

  .lessonActions {
    flex-direction: column;
    gap: 20px;
  }
}

@media screen and (max-width: 480px) {
  .courseTitle {
    font-size: 2rem;
  }

  .courseDescription {
    font-size: 1rem;
  }

  .lessonTitle {
    font-size: 1.5rem;
  }

  .lessonDescription {
    font-size: 1rem;
  }

  .filesTitle {
    font-size: 1.2rem;
  }

  .actionButton {
    font-size: 0.9rem;
  }
}