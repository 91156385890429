.page-resetar-senha-trocar-senha-token-trocar-senha-token {
  gap: 32px;
  width: 100%;
  height: fit-content;
  display: flex;
  padding: 24px;
  overflow: hidden;
  position: relative;
  max-width: 410px;
  align-self: stretch;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: center;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-white);
}
.page-resetar-senha-trocar-senha-token-frame347 {
  gap: 4px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.page-resetar-senha-trocar-senha-token-text {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 32px;
}
.page-resetar-senha-trocar-senha-token-text1 {
  color: var(--dl-color-gray-g200);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.page-resetar-senha-trocar-senha-token-container {
  gap: 16px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.page-resetar-senha-trocar-senha-token-textfields {
  gap: 8px;
  height: 64px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.page-resetar-senha-trocar-senha-token-text2 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.page-resetar-senha-trocar-senha-token-textfields1 {
  gap: 8px;
  height: 64px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.page-resetar-senha-trocar-senha-token-text3 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.page-resetar-senha-trocar-senha-token-buttons {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: var(--dl-color-primary-primary500);
}
.page-resetar-senha-trocar-senha-token-text4 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: center;
  line-height: 24px;
}
.page-resetar-senha-trocar-senha-token-root-class-name {
  align-self: center;
}
@media(max-width: 479px) {
  .page-resetar-senha-trocar-senha-token-text1 {
    color: var(--dl-color-gray-g200);
    line-height: 24px;
  }
}
