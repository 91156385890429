.header {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    position: sticky;
    top: 0;
  }
  
  .headerContent {
    max-width: 1248px;
    margin: 0 auto;
    padding: 0.75rem 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .options {
    display: flex;
    align-items: center;
    gap: 0;
    margin-right: 1.5rem;
  }
  
  .logoLink {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 3rem;
    width: auto;
  }
  
  .desktopNav {
    display: flex;
    gap: .75rem;
  }
  
  .navLink {
    color: var(--dl-color-gray-g300);
    text-decoration: none;
    font-size: 1rem;
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .navLink:hover,
  .navLink.active {
    color: var(--dl-color-primary-primary500);
    background-color: var(--dl-color-primary-primary50);
  }
  
  .navLink.active {
    font-weight: 600;
  }
  
  .toolsMenuContainer {
    position: relative;
    display: flex;
    align-items:center;
  }
  
  .toolsMenuButton {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
    transition: all 0.2s ease;
  }
  
  .toolsMenuButton:hover,
  .toolsMenuButton.active {
    color: var(--dl-color-primary-primary500);
    background-color: var(--dl-color-primary-primary50);
  }
  
  .chevron {
    transition: transform 0.2s ease;
  }
  
  .chevron.open {
    transform: rotate(180deg);
  }
  
  .toolsDropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    min-width: 200px;
    overflow: hidden;
    z-index: 1000;
    animation: fadeInDown 0.3s ease;
  }
  
  .toolsDropdownItem {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0.75rem 1rem;
    color: var(--dl-color-gray-g300);
    text-decoration: none;
    transition: all 0.2s ease;
    font-size: 0.875rem;
    width: 100%;
  }
  
  .toolsDropdownItem:hover {
    background-color: var(--dl-color-primary-primary50);
    color: var(--dl-color-primary-primary500);
  }
  
  .headerActions {
    display: flex;
    align-items: center;
  }
  
  .iconLink,
  .iconButton {
    color: var(--dl-color-gray-g300);
    font-size: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.2s ease;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .iconLink:hover,
  .iconButton:hover {
    color: var(--dl-color-primary-primary500);
    background-color: var(--dl-color-primary-primary50);
  }
  
  .notificationsContainer,
  .userMenuContainer {
    position: relative;
  }
  
  .notificationBadge {
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    background-color: var(--dl-color-primary-primary500);
    color: white;
    font-size: 0.75rem;
    min-width: 1.25rem;
    height: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    padding: 0 0.25rem;
  }
  
  .dropdownMenu {
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    background-color: white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    min-width: 16rem;
    max-width: 20rem;
    overflow: hidden;
    animation: fadeInDown 0.3s ease;
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-0.5rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .dropdownTitle {
    font-size: 1rem;
    font-weight: 600;
    color: var(--dl-color-default-black);
    padding: 1rem;
    border-bottom: 1px solid var(--dl-color-gray-g30);
  }
  
  .noItems {
    color: var(--dl-color-gray-g300);
    font-size: 0.875rem;
    text-align: center;
    padding: 1.5rem 1rem;
  }
  
  .notificationsList {
    max-height: 24rem;
    overflow-y: auto;
  }
  
  .notificationItem {
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-bottom: 1px solid var(--dl-color-gray-g30);
  }
  
  .notificationItem:last-child {
    border-bottom: none;
  }
  
  .notificationItem:hover {
    background-color: var(--dl-color-primary-primary50);
  }
  
  .notificationItem.unread {
    background-color: var(--dl-color-primary-primary50);
  }
  
  .notificationMessage {
    color: var(--dl-color-default-black);
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
  }
  
  .notificationTime {
    font-size: 0.75rem;
    color: var(--dl-color-gray-g300);
  }
  
  .userMenuButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .userAvatar {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .userInitials {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: var(--dl-color-primary-primary500);
    color: white;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dropdownItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    color: var(--dl-color-gray-g300);
    text-decoration: none;
    transition: all 0.2s ease;
    font-size: 0.875rem;
    width: 100%;
  }
  
  .dropdownItem:hover {
    background-color: var(--dl-color-primary-primary50);
    color: var(--dl-color-primary-primary500);
  }
  
  .mobileMenuButton {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--dl-color-gray-g300);
    cursor: pointer;
    padding-right: 0;
  }
  
  .mobileMenu {
    display: none;
  }
  
  .mobileToolsMenu {
    margin-top: 0.5rem;
  }
  
  .mobileToolsMenuButton {
    width: 100%;
    text-align: left;
    padding: 0.75rem;
    background: none;
    border: none;
    font-size: 1rem;
    color: var(--dl-color-gray-g300);
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .mobileToolsMenuButton:hover,
  .mobileToolsMenuButton.active {
    background-color: var(--dl-color-primary-primary50);
    color: var(--dl-color-primary-primary500);
  }
  
  .mobileToolsDropdownMenu {
    margin-left: 1rem;
  }
  
  .mobileToolsDropdownItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem;
    color: var(--dl-color-gray-g300);
    text-decoration: none;
    transition: all 0.2s ease;
    font-size: 0.875rem;
  }
  
  .mobileToolsDropdownItem:hover {
    background-color: var(--dl-color-primary-primary50);
    color: var(--dl-color-primary-primary500);
  }
  
  @media (max-width: 1024px) {
    .desktopNav {
      display: none;
    }
  
    .mobileMenuButton {
      display: block;
    }
  
    .mobileMenu {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      background-color: white;
      border-top: 1px solid var(--dl-color-gray-g30);
    }
  
    .mobileNavLink {
      color: var(--dl-color-gray-g300);
      text-decoration: none;
      font-size: 1rem;
      padding: 0.75rem;
      border-radius: 0.5rem;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  
    .mobileNavLink:hover,
    .mobileNavLink.active {
      color: var(--dl-color-primary-primary500);
      background-color: var(--dl-color-primary-primary50);
    }
  }
  
  @media (max-width: 768px) {
    .headerContent {
      padding: 0.5rem 1rem;
    }
  
    .logo {
      height: 2rem;
    }
  
    .headerActions {
      gap: 0.5rem;
    }
  
    .iconLink,
    .iconButton {
      font-size: 1.125rem;
    }
  
    .userAvatar,
    .userInitials {
      width: 2rem;
      height: 2rem;
    }
  
    .dropdownMenu,
    .toolsDropdownMenu {
      min-width: 14rem;
    }
  }
  
  @media (max-width: 480px) {
    .headerContent {
      padding: 12px 24px;
    }
  
    .options { 
      margin-right: .5rem;
      gap: -.2rem;
    }
  
    .logo {
      height: 1.9rem;
    }
  
    .iconLink,
    .iconButton {
      font-size: 1rem;
      height: 26px;
      width: 26px;
    }
  
    .userAvatar,
    .userInitials {
      width: 1.75rem;
      height: 1.75rem;
    }
  
    .dropdownMenu,
    .toolsDropdownMenu {
      min-width: 12rem;
      right: -0.5rem;
    }
  
    .mobileMenu {
      gap: .25rem;
    }
  
    .mobileMenu a {
      font-size: .8rem;
    }
  
    .mobileMenu a.active {
      font-weight: 600;
    }
  }