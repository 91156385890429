.emptyState {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4rem 2rem;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .emptyStateIcon {
    font-size: 4rem;
    color: #68b43a;
    margin-bottom: 1rem;
  }
  
  .emptyState h2 {
    font-size: 1.5rem;
    color: #333333;
    margin-bottom: 0.5rem;
  }
  
  .emptyState p {
    font-size: 1rem;
    color: #666666;
  }