:root {
  --color-primary: #68b43a;
  --color-primary-light: #7fc954;
  --color-primary-dark: #4e8c2a;
  --color-secondary: #223a13;
  --color-background: #f5fff0;
  --color-text: #333;
  --color-text-light: #666;
  --color-white: #fff;
  --color-gray-100: #f8f9fa;
  --color-gray-200: #e9ecef;
  --color-gray-300: #dee2e6;
  --color-gray-400: #ced4da;
  --color-gray-500: #adb5bd;
  --color-success: #28a745;
  --color-danger: #dc3545;
  --border-radius-sm: 4px;
  --border-radius: 8px;
  --border-radius-lg: 16px;
  --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  --box-shadow-hover: 0 4px 12px rgba(0, 0, 0, 0.12);
  --transition: 0.2s ease-in-out;
}

.minhasVendas {
  min-height: 100vh;
  background: linear-gradient(150deg, var(--color-background) 0%, #cfd5aa 100%);
}

.container {
  max-width: 1248px;
  margin: 0 auto;
  padding: clamp(1rem, 5vw, 2rem);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: clamp(1.5rem, 4vw, 2rem);
  flex-wrap: wrap;
  gap: 1rem;
}

.title {
  font-size: clamp(1.5rem, 5vw, 1.875rem);
  color: #223a13;
}

.actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.searchContainer {
  position: relative;
  flex-grow: 1;
  min-width: 250px;
}

.searchInput {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border: 1px solid var(--color-gray-300);
  border-radius: var(--border-radius);
  font-size: clamp(0.875rem, 2vw, 1rem);
  color: var(--color-text);
  transition: all var(--transition);
}

.searchInput:focus {
  outline: none;
  border-color: var(--color-primary);
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.2);
}

.searchIcon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-gray-400);
  font-size: clamp(1rem, 3vw, 1.25rem);
  pointer-events: none;
}

.filterContainer {
  position: relative;
}

.filterButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: var(--border-radius);
  font-size: clamp(0.875rem, 2vw, 1rem);
  color: var(--color-text);
  cursor: pointer;
  transition: all var(--transition);
  width: 100%;
}

.filterButton:hover {
  background-color: var(--color-gray-100);
}

.filterIcon {
  font-size: clamp(1rem, 3vw, 1.25rem);
  color: var(--color-primary);
  margin-left: auto;
}

.filterDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 0.5rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 0;
}

.filterDropdown li {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color var(--transition);
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.filterDropdown li:hover {
  background-color: var(--color-gray-100);
}

.refreshButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  background-color: var(--color-primary);
  border: none;
  border-radius: var(--border-radius);
  font-size: clamp(0.875rem, 2vw, 1rem);
  color: var(--color-white);
  cursor: pointer;
  transition: all var(--transition);
}

.refreshButton:hover {
  background-color: var(--color-primary-dark);
}

.refreshButton:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.refreshIcon {
  font-size: clamp(1rem, 3vw, 1.25rem);
}

.content {
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  padding: clamp(1rem, 3vw, 2rem);
  box-shadow: var(--box-shadow);
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  font-size: clamp(1rem, 2vw, 1.125rem);
  color: var(--color-text-light);
}

.error {
  color: var(--color-danger);
}

.tableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.table th,
.table td {
  padding: clamp(0.5rem, 2vw, 1rem);
  text-align: left;
  border-bottom: 1px solid var(--color-gray-200);
}

.table th {
  background-color: var(--color-gray-100);
  font-weight: 600;
  color: var(--color-text-light);
  position: sticky;
  top: 0;
  z-index: 10;
  font-size: clamp(0.75rem, 2vw, 0.875rem);
}

.table tr:last-child td {
  border-bottom: none;
}

.status {
  padding: 0.25rem 0.5rem;
  border-radius: var(--border-radius-sm);
  font-size: clamp(0.6875rem, 1.5vw, 0.75rem);
  font-weight: 600;
  text-transform: uppercase;
}

.statusaprovada {
  background-color: var(--color-success);
  color: var(--color-white);
}

.statuscancelada {
  background-color: var(--color-danger);
  color: var(--color-white);
}

.infoButton {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--color-primary);
  font-size: clamp(1rem, 3vw, 1.25rem);
  transition: color var(--transition);
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.paginationButton {
  padding: clamp(0.25rem, 1vw, 0.5rem) clamp(0.5rem, 1.5vw, 0.75rem);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-300);
  border-radius: var(--border-radius);
  color: var(--color-text);
  cursor: pointer;
  transition: all var(--transition);
  font-size: clamp(0.75rem, 2vw, 1rem);
}

.paginationButton.active {
  background-color: var(--color-primary);
  color: var(--color-white);
  border-color: var(--color-primary);
}

.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background-color: var(--color-white);
  padding: clamp(1rem, 3vw, 1.5rem);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.popup h2 {
  margin-top: 0;
  color: #223a13;
  font-size: clamp(1.25rem, 3vw, 1.5rem);
}

.popup p {
  margin: 0.5rem 0;
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.popupClose {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition);
  font-size: clamp(0.875rem, 2vw, 1rem);
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: clamp(2rem, 5vw, 3rem) 1rem;
  text-align: center;
}

.emptyStateIcon {
  font-size: clamp(3rem, 10vw, 5rem);
  color: var(--color-primary);
  margin-bottom: 1.5rem;
  animation: pulse 2s infinite;
}

.emptyStateTitle {
  font-size: clamp(1.25rem, 4vw, 1.75rem);
  font-weight: 700;
  color: #223a13;
  margin-bottom: 1rem;
}

.emptyStateDescription {
  font-size: clamp(0.875rem, 2.5vw, 1.125rem);
  color: var(--color-text-light);
  margin-bottom: 2rem;
  line-height: 1.5;
}

.emptyStateButton {
  padding: 0.75rem 1.5rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: none;
  border-radius: 30px;
  font-size: clamp(0.875rem, 2.5vw, 1.125rem);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(104, 180, 58, 0.3);
}

@media (max-width: 768px) {
  .actions {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .searchContainer,
  .filterContainer,
  .refreshButton {
    width: 100%;
  }

  .table {
    display: block;
  }

  .table thead {
    display: none;
  }

  .table tbody,
  .table tr,
  .table td {
    display: block;
  }

  .table tr {
    margin-bottom: 1rem;
    border: 1px solid var(--color-gray-300);
    border-radius: var(--border-radius);
    padding: 1rem;
  }

  .table td {
    border-bottom: none;
    padding: 0.5rem 0;
  }

  .table td::before {
    content: attr(data-label);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--color-text-light);
    display: block;
    margin-bottom: 0.25rem;
  }

  .pagination {
    justify-content: flex-start;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }
}

@media (hover: hover) {
  .searchInput:hover,
  .filterButton:hover,
  .refreshButton:hover,
  .paginationButton:hover,
  .popupClose:hover,
  .emptyStateButton:hover {
    box-shadow: var(--box-shadow-hover);
  }

  .table tr:hover {
    background-color: var(--color-gray-100);
  }

  .infoButton:hover {
    color: var(--color-primary-dark);
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInFromBottom {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.content {
  animation: fadeIn 0.3s ease-out;
}

.table tr {
  animation: slideInFromBottom 0.3s ease-out;
  animation-fill-mode: both;
}

.table tr:nth-child(1) { animation-delay: 0.05s; }
.table tr:nth-child(2) { animation-delay: 0.1s; }
.table tr:nth-child(3) { animation-delay: 0.15s; }
.table tr:nth-child(4) { animation-delay: 0.2s; }
.table tr:nth-child(5) { animation-delay: 0.25s; }

.searchInput:focus,
.filterButton:focus,
.refreshButton:focus,
.paginationButton:focus,
.popupClose:focus,
.emptyStateButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.4);
}

.tableContainer::-webkit-scrollbar {
  height: 8px;
}

.tableContainer::-webkit-scrollbar-track {
  background: var(--color-gray-100);
}

.tableContainer::-webkit-scrollbar-thumb {
  background-color: var(--color-gray-400);
  border-radius: 20px;
}

.tableContainer::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-gray-500);
}

@supports (overflow-x: auto) and (overflow-y: clip) {
  .tableContainer {
    overflow-x: auto;
    overflow-y: clip;
  }
}

@supports not (overflow-y: clip) {
  .tableContainer {
    overflow-x: auto;
    overflow-y: visible;
  }
}

@media (max-width: 480px) {
  .pagination {
    justify-content: center;
  }

  .paginationButton {
    padding: 0.5rem;
    min-width: 2rem;
    text-align: center;
  }

  .paginationButton:first-child,
  .paginationButton:last-child {
    display: none;
  }
}

@supports (display: grid) {
  .actions {
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 1rem;
  }

  @media (max-width: 768px) {
    .actions {
      grid-template-columns: 1fr;
    }
  }
}

@supports (backdrop-filter: blur(10px)) {
  .popupOverlay {
    backdrop-filter: blur(10px);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: #1a1a1a;
    --color-text: #f0f0f0;
    --color-text-light: #a0a0a0;
    --color-white: #2a2a2a;
    --color-gray-100: #333333;
    --color-gray-200: #404040;
    --color-gray-300: #4d4d4d;
    --color-gray-400: #666666;
    --color-gray-500: #808080;
  }

  .minhasVendas {
    background: linear-gradient(150deg, var(--color-background) 0%, #2a3a1a 100%);
  }

  .table th {
    background-color: var(--color-gray-200);
  }

  .filterButton,
  .searchInput {
    background-color: var(--color-gray-100);
    color: var(--color-text);
    border-color: var(--color-gray-300);
  }

  .filterDropdown {
    background-color: var(--color-gray-100);
    border-color: var(--color-gray-300);
  }

  .filterDropdown li:hover {
    background-color: var(--color-gray-200);
  }

  .popup {
    background-color: var(--color-gray-100);
  }

  .popupClose {
    background-color: var(--color-primary-dark);
  }

  .emptyStateDescription {
    color: var(--color-text-light);
  }
}

@media (hover: none) {
  .searchInput:active,
  .filterButton:active,
  .refreshButton:active,
  .paginationButton:active,
  .popupClose:active,
  .emptyStateButton:active {
    transform: scale(0.98);
  }
}

@supports (font-size: clamp(1rem, 2vw, 1.5rem)) {
  .title {
    font-size: clamp(1.5rem, 5vw, 1.875rem);
  }

  .searchInput,
  .filterButton,
  .refreshButton,
  .paginationButton {
    font-size: clamp(0.875rem, 2vw, 1rem);
  }

  .emptyStateTitle {
    font-size: clamp(1.25rem, 4vw, 1.75rem);
  }

  .emptyStateDescription {
    font-size: clamp(0.875rem, 2.5vw, 1.125rem);
  }
}

@supports (writing-mode: vertical-rl) {
  @media (max-width: 480px) {
    .table th {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      white-space: nowrap;
      padding: 1rem 0.5rem;
    }
  }
}