.approvalPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    backdrop-filter: blur(5px);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .approvalContainer {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 32px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 550px;
    width: 90%;
    position: relative;
    animation: slideIn 0.3s ease-out;
  }
  
  @keyframes slideIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    font-size: 24px;
    color: #495057;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .closeButton:hover {
    color: #68b43a;
  }
  
  .title {
    color: #2c3e50;
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .optionsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .optionButton {
    background-color: #f8f9fa;
    border: 2px solid #e9ecef;
    border-radius: 12px;
    padding: 1rem;
    font-size: 1rem;
    font-weight: 600;
    color: #495057;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .optionButton:hover {
    background-color: #e9ecef;
  }
  
  .optionButton.selected {
    background-color: #68b43a;
    border-color: #68b43a;
    color: #ffffff;
  }
  
  .confirmationSection {
    margin-bottom: 2rem;
  }
  
  .confirmationPhrase {
    font-size: 1rem;
    font-weight: 600;
    color: #495057;
    margin-bottom: 0.5rem;
    text-align: center;
    letter-spacing: 1px;
  }
  
  .confirmationPhrase span {
    transition: color 0.3s ease;
  }
  
  .confirmationPhrase span.correct {
    color: #68b43a;
  }
  
  .confirmationInputContainer {
    position: relative;
  }
  
  .confirmationInput {
    width: 100%;
    padding: 1rem;
    font-size: 1rem;
    border: 2px solid #ced4da;
    border-radius: 12px;
    background-color: transparent;
    transition: border-color 0.3s ease;
  }
  
  .confirmationInput:focus {
    outline: none;
    border-color: #68b43a;
  }
  
  .confirmButton {
    background-color: #68b43a;
    color: #ffffff;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 12px;
    font-size: 1.1rem;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease;
    display: block;
    width: 100%;
  }
  
  .confirmButton:hover {
    background-color: #5a9c2e;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .confirmButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  @media (max-width: 768px) {
    .approvalContainer {
      padding: 24px;
    }
  
    .title {
      font-size: 1.5rem;
    }
  
    .optionsContainer {
      grid-template-columns: 1fr;
    }
  
    .optionButton, .confirmationInput, .confirmButton {
      font-size: 0.9rem;
    }
  }