.tabContainerWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .tabContainer {
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    gap: 8px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }
  
  .tabContainer::-webkit-scrollbar {
    display: none;
  }
  
  .tabScrollButton {
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    opacity: 0.7;
  }
  
  .tabScrollButton:hover {
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 1;
  }
  
  .tabScrollButtonLeft {
    left: -16px;
  }
  
  .tabScrollButtonRight {
    right: -16px;
  }
  
  .tabButton {
    background-color: #ffffff;
    color: #333333;
    border: none;
    border-radius: 12px;
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
    flex-shrink: 0;
    scroll-snap-align: start;
  }
  
  .tabButton.active {
    background-color: #68b43a;
    color: #ffffff;
  }
  
  .badge {
    background-color: #6c757d;
    color: #ffffff;
    border-radius: 12px;
    padding: 0.2rem 0.5rem;
    font-size: 0.75rem;
    margin-left: 0.5rem;
  }
  
  .tabButton.active .badge {
    background-color: #ffffff;
    color: #68b43a;
  }
  
  @media screen and (max-width: 768px) {
    .tabContainer {
      justify-content: flex-start;
    }
  
    .tabButton {
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
    }
  }