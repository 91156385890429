.manualSaleMain {
  min-height: 100vh;
  background: linear-gradient(150deg, #f5fff0 0%, #cfd5aa 100%);
}

.manualSaleContainer {
  max-width: 900px;
  margin: 0 auto;
  padding: 2rem 24px;
}

.manualSaleTitle {
  font-size: 1.875rem;
  color: #223a13;
  margin-bottom: 0.5rem;
  text-align: center;
}

.manualSaleDescription {
  font-size: 1rem;
  color: #495057;
  margin-bottom: 2rem;
  text-align: center;
}

.salePanel {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.searchSection,
.orderSection {
  margin-bottom: 2rem;
}

.inputGroup {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
}

.input:focus {
  outline: none;
  border-color: #68b43a;
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.2);
}

.searchButton,
.registerButton {
  background-color: #68b43a;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.searchButton:hover:not(:disabled),
.registerButton:hover:not(:disabled) {
  background-color: #5a9d32;
  transform: translateY(-2px);
}

.searchButton:disabled,
.registerButton:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

.registerButton {
  margin: 0 auto;
  padding: 1rem 2rem;
}

.userInfo {
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 1.5rem;
  margin-top: 1rem;
}

.userInfo h3 {
  color: #223a13;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.userInfo p {
  margin-bottom: 0.5rem;
  color: #495057;
}

.userInfo p:last-child {
  margin-bottom: 0;
}

.searchResults {
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-top: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
}

.userResult {
  padding: 1rem;
  border-bottom: 1px solid #ced4da;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.userResult:last-child {
  border-bottom: none;
}

.userResult:hover {
  background-color: #f8f9fa;
}

.userResult p {
  margin: 0.25rem 0;
}

@media screen and (max-width: 768px) {
  .manualSaleContainer {
    padding: 1rem;
  }

  .manualSaleTitle {
    font-size: 1.5rem;
  }

  .inputGroup {
    flex-direction: column;
  }

  .searchButton {
    width: 100%;
  }
} 