.perfil-form-dados-bancarios-perfil-form-dados-bancarios {
  gap: 32px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  position: relative;
  align-self: stretch;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746);
  align-items: flex-start;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-default-white);
}
.perfil-form-dados-bancarios-frame354 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
  cursor: pointer;
}
.perfil-form-dados-bancarios-text {
  color: var(--dl-color-default-black);
  height: auto;
  text-align: left;
  line-height: 24px;
}
.perfil-form-dados-bancarios-keyboardarrowup {
  width: 24px;
  height: 24px;
}
.perfil-form-dados-bancarios-keyboardarrowdown {
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
}
.perfil-form-dados-bancarios-frame308 {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid var(--dl-color-gray-900);
  padding-top: 32px;
}
.perfil-form-dados-bancarios-text01 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.perfil-form-dados-bancarios-frame309 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.perfil-form-dados-bancarios-textfields {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.perfil-form-dados-bancarios-text03 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-bancarios-frame2 {
  gap: 8px;
  height: 40px;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  padding-top: 0px;
  padding-left: 16px;
  border-radius: 4px;
  position: relative;
  padding-right: 0px;
  padding-bottom: 0px;
  background-color: var(--dl-color-gray-g30);
}
.perfil-form-dados-bancarios-search {
  width: 24px;
  height: 24px;
}
.perfil-form-dados-bancarios-textinput {
  height: 100%;
  padding-right: 16px;
}
.perfil-form-dados-bancarios-frame312 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
}
.perfil-form-dados-bancarios-textfields1 {
  gap: 8px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  position: relative;
  flex-shrink: 0;
  flex-direction: column;
}
.perfil-form-dados-bancarios-text05 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-bancarios-textfields2 {
  gap: 8px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.perfil-form-dados-bancarios-text07 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-bancarios-textfields3 {
  gap: 8px;
  height: 86px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.perfil-form-dados-bancarios-text09 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-bancarios-text11 {
  color: var(--dl-color-gray-g300);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 14px;
}
.perfil-form-dados-bancarios-textfields4 {
  gap: 8px;
  height: 64px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.perfil-form-dados-bancarios-text13 {
  color: var(--dl-color-default-black);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.perfil-form-dados-bancarios-frame306 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.perfil-form-dados-bancarios-buttons {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: rgba(104, 180, 58, 1);
}
.perfil-form-dados-bancarios-text15 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: center;
  line-height: 24px;
}
@media (max-width: 991px) {
  .perfil-form-dados-bancarios-textfields1 {
    flex: 1;
    width: auto;
  }
  .perfil-form-dados-bancarios-textfields2 {
    flex: 1;
    width: auto;
  }
}
@media (max-width: 479px) {
  .perfil-form-dados-bancarios-perfil-form-dados-bancarios {
    height: fit-content;
  }
  .perfil-form-dados-bancarios-frame2 {
    gap: 0;
    align-items: center;
  }
  .perfil-form-dados-bancarios-frame312 {
    flex-direction: column;
  }
  .perfil-form-dados-bancarios-textfields1 {
    width: 100%;
  }
  .perfil-form-dados-bancarios-textfields2 {
    width: 100%;
  }
}

.page-cadastro-form-step4-container1 {
  top: 46.5%;
  left: 24px;
  width: calc(100% - 48px);
  display: flex;
  overflow: auto;
  background: white;
  height: 150px;
  align-items: flex-start;
  border-color: hsl(0, 0%, 76%);
  border-width: 1px;
  border-radius: 0px 0px 4px 4px;
  padding: 0;
  flex-direction: column;
  position: absolute;
  z-index: 1;
}

.page-cadastro-form-step4-bank-item {
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.page-cadastro-form-step4-bank-item:hover{
  background-color: rgba(0, 0, 0, 0.2);
}