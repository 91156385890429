.resetar-senha-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.resetar-senha-recuperarsenha {
  gap: 40px;
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  padding: 24px 16px;
  overflow: hidden;
  background: linear-gradient(150deg, rgba(245, 255, 240, 1) 0%, rgba(207, 213, 170, 1) 100%);
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gradient-primary);
}
.resetar-senha-l2 {
  width: 194px;
  height: 92px;
}
