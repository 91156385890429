:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-color-gray-g30: rgba(235, 235, 235, 1);
  --dl-color-gray-g40: rgba(223, 223, 222, 1);
  --dl-color-gray-7001: rgba(30, 33, 37, 1);
  --dl-color-gray-7002: rgba(30, 33, 37, 1);
  --dl-color-gray-g200: rgba(106, 108, 104, 1);
  --dl-color-gray-g300: rgba(91, 93, 89, 1);
  --dl-color-gray-g400: rgba(78, 81, 76, 1);
  --dl-color-gray-g900: rgba(6, 10, 3, 1);
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1400px;
  --dl-color-default-black: rgba(6, 10, 3, 1);
  --dl-color-default-white: rgba(255, 255, 255, 1);
  --dl-color-error-error50: rgba(252, 238, 238, 1);
  --dl-radius-radius-round: 50%;
  --dl-color-error-error500: rgba(228, 86, 86, 1);
  --dl-color-error-error700: rgba(162, 62, 62, 1);
  --dl-color-error-error900: rgba(96, 37, 37, 1);
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-color-gradient-primary: rgba(245, 255, 240, 1);
  --dl-space-space-threeunits: 48px;
  --dl-color-primary-primary50: rgba(240, 248, 235, 1);
  --dl-color-primary-primary500: rgba(104, 180, 58, 1);
  --dl-color-primary-primary700: rgba(74, 128, 41, 1);
  --dl-color-primary-primary800: rgba(57, 99, 32, 1);
  --dl-color-primary-primary900: rgba(44, 76, 24, 1);
  --dl-color-success-success500: rgba(140, 228, 86, 1);
  --dl-space-space-oneandhalfunits: 24px;
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
button {
  cursor:pointer!important;
}

.input {
  color: var(--dl-color-gray-500);
  width: 100%;
  cursor: auto;
  height: 40px;
  background: g30;
  padding-left: 16px;
  border-radius: 4px;
  padding-right: 16px;
  background-color: var(--dl-color-gray-g30);
}
.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: none !important;
}
.TextExtrasmall-12Regular {
  font-size: 12px;
  font-style: normal;
  font-family: Inter;
  font-weight: 400px;
  font-stretch: normal;
}
.HeaderH1-24Medium {
  font-size: 24px;
  font-style: normal;
  font-family: Inter;
  font-weight: 500px;
  font-stretch: normal;
}
.Content {
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.TextSmall-14Medium {
  font-size: 14px;
  font-style: normal;
  font-family: Inter;
  font-weight: 500px;
  font-stretch: normal;
}
.TextSmall-14Regular {
  font-size: 14px;
  font-style: normal;
  font-family: Inter;
  font-weight: 400px;
  font-stretch: normal;
}
.TextMedium-16Regular {
  font-size: 16px;
  font-style: normal;
  font-family: Inter;
  font-weight: 400px;
  font-stretch: normal;
}
.HeaderH2-20Medium {
  font-size: 20px;
  font-style: normal;
  font-family: Inter;
  font-weight: 500px;
  font-stretch: normal;
}
.TextMedium-16Medium {
  font-size: 16px;
  font-style: normal;
  font-family: Inter;
  font-weight: 500px;
  font-stretch: normal;
}
.HeaderH3-18Medium {
  font-size: 18px;
  font-style: normal;
  font-family: Inter;
  font-weight: 500px;
  font-stretch: normal;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

input {
  outline:none;
}

a{
  text-decoration: none;
  cursor: pointer;
}

.loader-container {
  display:flex;
  height:100vh;
  width:100vw;
  align-items: center;
  justify-content: center;
  background: linear-gradient(150deg, rgba(245, 255, 240, 1) 0%, rgba(207, 213, 170, 1) 100%);
  flex-direction: column;
  gap:20px;
}

.loader-container img {
  width:150px;
  height:auto;
}

.loader-container span {
  border-color: rgb(50 225 16) rgb(59 211 15) transparent!important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border: 1px solid rgba(192, 193, 194, 1);
  border-radius: 4px;
  background-color: #ffffff;
  color: var(--dl-color-gray-g300);
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100;
  border-radius: 4px;
  height: auto;
  border: 1px solid rgba(192, 193, 194, 1);
  overflow:hidden;
}

.dropdown-content a {
  color: var(--dl-color-gray-7001);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content, .dropdown:hover .datepicker-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #eaeaea;
}

/* Estilo personalizado para barra de rolagem */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

/* Remove as setinhas no topo e na base */
::-webkit-scrollbar-button {
  display: none;
}

/* Para Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

ul {
  list-style: none;
}