:root {
  --primary-color: #68b43a;
  --secondary-color: #6c757d;
  --background-gradient: linear-gradient(150deg, #f5fff0, #cfd5aa);
  --text-color: #223a13;
  --card-background: #fff;
  --card-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  --border-radius: 8px;
}

.dashboard {
  background: var(--background-gradient);
  min-height: 100vh;
  box-sizing: border-box;
}

.dashboard h1 {
  font-size: clamp(22px, 4vw, 30px);
  color: var(--text-color);
  margin-bottom: clamp(16px, 3vw, 32px);
}

.dashboardContainer {
  max-width: 1248px;
  margin: 0 auto;
  padding: 2rem 24px;
}

.metricsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 250px), 1fr));
  gap: clamp(16px, 3vw, 24px);
  margin-bottom: clamp(24px, 5vw, 48px);
}

.chartsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 400px), 1fr));
  gap: clamp(16px, 3vw, 24px);
}

.metricCard, .chartCard, .rankingCard {
  background-color: var(--card-background);
  padding: clamp(16px, 3vw, 24px);
  border-radius: var(--border-radius);
  box-shadow: var(--card-shadow);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.metricCard {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.metricCard:hover, .chartCard:hover, .rankingCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.metricIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(48px, 8vw, 64px);
  height: clamp(48px, 8vw, 64px);
  border-radius: 50%;
  margin-right: clamp(12px, 3vw, 16px);
  flex-shrink: 0;
}

.primaryBg { background-color: #68b43a; color: #fff; }
.secondaryBg { background-color: #6c757d; color: #fff; }
.moneyBg { background-color: #28a745; color: #fff; }
.successBg { background-color: #17a2b8; color: #fff; }
.starBg { background-color: #ffc107; color: #fff; }

.metricContent {
  flex: 1;
}

.metricContent h3 {
  margin-bottom: 8px;
  font-size: clamp(14px, 2.5vw, 18px);
  font-weight: bold;
  color: #333;
}

.metricValue {
  font-size: clamp(20px, 3.5vw, 28px);
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
}

.metricPeriod {
  font-size: clamp(12px, 1.8vw, 14px);
  color: #777;
}

.chartCard {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.chartCard h3 {
  margin-bottom: clamp(12px, 2vw, 16px);
  font-size: clamp(16px, 2.5vw, 20px);
  font-weight: bold;
  color: #333;
}

.rankingCard {
  padding: clamp(20px, 4vw, 28px);
}

.rankingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: clamp(16px, 3vw, 24px);
  flex-wrap: wrap;
  gap: 16px;
}

.rankingHeader h3 {
  font-size: clamp(18px, 3vw, 24px);
  font-weight: 700;
  color: var(--text-color);
  margin: 0;
}

.rankingTypeSelector {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 24px;
  padding: 4px;
  flex-wrap: wrap;
  justify-content: center;
}

.rankingTypeButton {
  background: none;
  border: none;
  padding: 8px 12px;
  font-size: clamp(12px, 1.8vw, 14px);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 20px;
  color: #555;
  flex: 1;
  text-align: center;
  white-space: nowrap;
}

.rankingTypeButton.active {
  background-color: #68b43a;
  color: #ffffff;
}

.rankingPeriodSelector {
  display: flex;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 4px;
  margin: 16px auto -16px auto;
}

.rankingPeriodButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 16px;
  color: #555;
  margin: 0 4px;
}

.rankingPeriodButton.active {
  background-color: #ffffff;
  color: var(--primary-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rankingPeriodButton:hover:not(.active) {
  background-color: rgba(255, 255, 255, 0.5);
}

.rankingPeriodIcon {
  margin-right: 8px;
  font-size: 18px;
}


.rankingList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.rankingLink {
  text-decoration: none;
  color: inherit;
}

.rankingItem {
  display: flex;
  align-items: center;
  padding: clamp(12px, 2vw, 16px);
  border-bottom: 1px solid #eee;
  transition: background-color 0.3s ease;
}

.rankingItem:hover {
  background-color: #f9f9f9;
}

.rankingPosition {
  font-weight: 700;
  margin-right: clamp(8px, 2vw, 16px);
  font-size: clamp(14px, 2.5vw, 18px);
  color: #333;
  width: 30px;
  text-align: center;
}

.rankingAvatar, .rankingAvatarPlaceholder {
  width: clamp(36px, 6vw, 48px);
  height: clamp(36px, 6vw, 48px);
  border-radius: 50%;
  margin-right: clamp(8px, 2vw, 16px);
  flex-shrink: 0;
}

.rankingAvatar {
  object-fit: cover;
  border: 2px solid #e0e0e0;
}

.rankingAvatarPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  font-size: clamp(12px, 2vw, 16px);
  font-weight: 700;
  text-transform: uppercase;
  color: #555;
}

.rankingName {
  flex: 1;
  font-size: clamp(14px, 2vw, 16px);
  font-weight: 600;
  color: #333;
}

.rankingPoints {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: clamp(14px, 2vw, 18px);
  color: #68b43a;
  background-color: #f5fff0;
  padding: 6px 10px;
  border-radius: 16px;
  min-width: 80px;
  text-align: right;
}

.rankingPointsIcon {
  width: clamp(16px, 3vw, 20px);
  height: clamp(16px, 3vw, 20px);
  margin-left: 6px;
}

.rankingIndicationsText {
  font-size: clamp(12px, 1.8vw, 14px);
  color: #555;
  margin-left: 4px;
}

.ratingCard .btn {
  margin-top: 12px;
  display: inline-block;
  font-weight: 600;
  padding: 8px 16px;
  font-size: clamp(12px, 1.8vw, 14px);
  background: #ffc107;
  color: #333;
  border-radius: 20px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.ratingCard .btn:hover {
  background: #ffca2c;
  transform: translateY(-2px);
}

.chartContainer {
  margin-top: clamp(16px, 3vw, 20px);
  width: 100%;
  height: clamp(300px, 50vh, 400px);
}

@media (max-width: 768px) {
  .rankingHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .rankingTypeSelector {
    width: 100%;
    justify-content: space-between;
    margin-top: 12px;
  }

  .rankingPeriodSelector {
    width: 100%;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 8px;
  }

  .rankingPoints {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .rankingIndicationsText {
    margin-left: 4px;
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .metricCard {
    flex-direction: column;
    text-align: center;
  }

  .metricIcon {
    margin-right: 0;
    margin-bottom: 12px;
  }

  .rankingItem {
    flex-wrap: nowrap;
  }

  .rankingName {
    width: auto;
    margin-bottom: 0;
  }

  .rankingPoints {
    width: auto;
    justify-content: flex-end;
  }
}