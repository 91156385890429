:root {
    --primary-color: #6c757d;
    --secondary-color: #17a2b8;
    --accent-color: #68b43a;
    --background-color: linear-gradient(150deg, #f5fff0, #cfd5aa);
    --card-background-color: #ffffff;
    --text-color: #333333;
    --inactive-color: #999999;
    --border-radius: 8px;
    --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .avaliacoes-main {
    min-height: calc(100vh - 96px);
    background: var(--background-color);
  }
  
  .avaliacoes-container {
    padding: 40px 24px;
    max-width: 1248px;
    margin: 0 auto;
  }
  
  .avaliacoes-title {
    font-size: 1.875rem;
    color: #223a13;
    margin-bottom: 2rem;
  }
  
  .review-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
  }
  
  .review-card {
    background-color: var(--card-background-color);
    border-radius: var(--border-radius);
    padding: 1.5rem;
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
  }
  
  .review-card:hover {
    transform: translateY(-4px);
  }
  
  .review-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
  }
  
  .profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .profile-acronym {
    width: 100%;
    height: 100%;
    background-color: var(--primary-color);
    color: var(--card-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .review-info {
    display: flex;
    flex-direction: column;
  }
  
  .review-name {
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 0.5rem;
  }
  
  .review-rating {
    display: flex;
    gap: 0.25rem;
    margin-bottom: 0.5rem;
  }
  
  .review-date {
    font-size: 0.875rem;
    color: var(--inactive-color);
  }
  
  .review-comment {
    font-size: 1rem;
    color: var(--text-color);
    margin-top: 1rem;
  }
  
  .expand-button {
    background: none;
    border: none;
    color: var(--accent-color);
    cursor: pointer;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
  
  .expand-button:hover {
    text-decoration: underline;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }
  
  .pagination-button {
    background-color: var(--card-background-color);
    color: var(--text-color);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: var(--box-shadow);
  }
  
  .pagination-button.active {
    background-color: var(--primary-color);
    color: var(--card-background-color);
  }
  
  @media screen and (max-width: 1000px) {
    .review-list {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  }
  