.cadastroContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: clamp(1rem, 5vw, 2rem);
  background: linear-gradient(150deg, #f5fff0 0%, #cfd5aa 100%);
}

.logo {
  width: clamp(100px, 30vw, 150px);
  margin-bottom: clamp(1rem, 5vh, 2rem);
  animation: fadeInDown 0.5s ease-out;
}

.formContainer {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: clamp(1.5rem, 5vw, 3rem);
  width: 100%;
  max-width: min(500px, 90vw);
  animation: fadeInUp 0.5s ease-out;
}

.title {
  text-align: center;
  color: #333;
  margin-bottom: clamp(1rem, 5vh, 2rem);
  font-size: clamp(1.5rem, 5vw, 2rem);
}

.progressBar {
  height: 4px;
  background-color: #e9ecef;
  border-radius: 2px;
  margin-bottom: clamp(1rem, 5vh, 2rem);
  overflow: hidden;
}

.progressFill {
  height: 100%;
  background-color: #68b43a;
  transition: width 0.3s ease;
}

.stepTitle {
  color: #68b43a;
  margin-bottom: 1.5rem;
  font-size: clamp(1.2rem, 4vw, 1.5rem);
}

.fieldContainer {
  margin-bottom: 1.5rem;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
  color: #495057;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
}

.input,
.input[type="date"],
select {
  width: 100%;
  padding: 0.75rem;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input:focus:not(.socialFieldContainer .input),
select:focus,
.input:focus .socialFieldContainer {
  border-color: #68b43a;
  outline: none;
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.25);
}

.errorMessage {
  color: #dc3545;
  font-size: clamp(0.8rem, 2vw, 0.875rem);
  margin-top: 0.25rem;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  margin-top: clamp(1.5rem, 5vh, 2rem);
  gap: 1rem;
}

.button {
  padding: 0.75rem 1.5rem;
  font-size: clamp(0.9rem, 2.5vw, 1rem);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  flex: 1;
}

.button:active {
  transform: scale(0.98);
}

.primary {
  background-color: #68b43a;
  color: white;
}

.primary:hover {
  background-color: #5a9d32;
}

.secondary {
  background-color: #6c757d;
  color: white;
}

.secondary:hover {
  background-color: #5a6268;
}

.socialMediaContainer {
  margin-bottom: 1.5rem;
}

.socialMediaInfo {
  font-size: clamp(0.8rem, 2vw, 0.9rem);
  color: #495057;
  margin-bottom: 1rem;
}

.socialFieldContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.socialFieldGroup {
  display: flex;
  align-items: center;
}

.icon {
  width: clamp(30px, 8vw, 40px);
  margin-right: 0.5rem;
}

.socialInputWrapper {
  display: flex;
  flex-grow: 1;
  border: 1px solid #ced4da;
  border-radius: 4px;
  overflow: hidden;
}

.baseUrl {
  background-color: #e9ecef;
  color: #495057;
  padding: 0.75rem;
  font-size: clamp(0.8rem, 2vw, 1rem);
  border-right: 1px solid #ced4da;
  white-space: nowrap;
}

.socialInput {
  flex-grow: 1;
  border: none;
  padding: 0.75rem;
  font-size: clamp(0.8rem, 2vw, 1rem);
}

.termsContainer {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.viewContractButton {
  background-color: #68b43a;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.viewContractButton:hover {
  background-color: #5a9d32;
}

.termsAcceptedText {
  font-size: 0.9rem;
  color: #495057;
}

.checkmark {
  color: #68b43a;
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.contractModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.contractModalContent {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contractViewer {
  flex-grow: 1;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-bottom: 1rem;
  height: 60vh;
  overflow-y: auto;
}

.contractViewer iframe {
  border: none;
  min-width: 100%;
  min-height: 100%;
}

.contractProgressBar {
  height: 4px;
  background-color: #e9ecef;
  border-radius: 2px;
  margin-bottom: 1rem;
  overflow: hidden;
}

.contractProgressFill {
  height: 100%;
  background-color: #68b43a;
  transition: width 0.3s ease;
}

.acceptButton {
  background-color: #68b43a;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 1rem;
}

.acceptButton:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

.acceptButton.active {
  background-color: #68b43a;
}

.acceptButton.active:hover {
  background-color: #5a9d32;
}

.downloadButton {
  background-color: #6c757d;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloadButton:hover {
  background-color: #5a6268;
}

.downloadButton svg {
  margin-right: 0.5rem;
}

.errorMessage {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

@media (max-width: 600px) {
  .contractModalContent {
    padding: 1rem;
    width: 95%;
  }

  .contractViewer {
    height: 50vh;
  }
}

.strengthMeter {
  margin-top: 0.75rem;
  width: 100%;
  height: 8px;
  background-color: #e9ecef;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.strengthBar {
  height: 100%;
  transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.strengthText {
  display: block;
  margin-top: 0.5rem;
  font-size: clamp(0.8rem, 2vw, 0.875rem);
  font-weight: 600;
  text-align: right;
  transition: color 0.3s ease-in-out;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 600px) {
  .buttonContainer {
    flex-direction: column-reverse;
  }
}

@media (prefers-reduced-motion: reduce) {
  .logo,
  .formContainer {
    animation: none;
  }

  .progressFill,
  .input,
  select,
  .button {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .formContainer {
    max-width: 600px;
  }

  .input,
  .input[type="date"],
  select,
  .button {
    font-size: 1.1rem;
  }
}

@media (min-height: 800px) {
  .cadastroContainer {
    justify-content: center;
  }
}

@media (max-height: 600px) {
  .logo {
    margin-bottom: 1rem;
  }

  .formContainer {
    padding: 1rem;
  }

  .fieldContainer {
    margin-bottom: 1rem;
  }
}

.bankFieldContainer {
  position: relative;
}

.bankInputWrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.searchIcon {
  position: absolute;
  left: 10px;
  color: #495057;
}

.bankInput {
  padding-left: 35px;
}

.resetButton {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  color: #495057;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ced4da;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdownItem {
  padding: 0.75rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdownItem:hover {
  background-color: #f8f9fa;
}

.successContainer {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.06);
  padding: clamp(1.5rem, 4vw, 2.5rem);
  text-align: center;
  max-width: min(85%, 420px);
  width: 100%;
  margin: 2rem auto;
  animation: fadeInScale 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes fadeInScale {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

.successIcon {
  font-size: clamp(2.5rem, 8vw, 4rem);
  color: #68b43a;
  margin-bottom: clamp(0.75rem, 2vw, 1.25rem);
}

.successTitle {
  font-size: clamp(1.25rem, 3.5vw, 1.75rem);
  color: #223a13;
  margin-bottom: clamp(0.5rem, 1.5vw, 0.75rem);
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.3;
}

.successMessage {
  font-size: clamp(0.9rem, 2.2vw, 1.1rem);
  color: #4a5568;
  margin-bottom: clamp(0.75rem, 2vw, 1rem);
  line-height: 1.5;
}

.successSubMessage {
  font-size: clamp(0.8rem, 1.8vw, 0.95rem);
  color: #718096;
  margin-bottom: clamp(1.25rem, 3.5vw, 2rem);
  line-height: 1.4;
}

.returnButton {
  background-color: #68b43a;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: clamp(0.85rem, 2vw, 0.95rem);
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  letter-spacing: 0.01em;
  box-shadow: 0 2px 4px rgba(104, 180, 58, 0.2);
}

.returnButton:hover {
  background-color: #5a9d32;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(104, 180, 58, 0.25);
}

.returnButton:active {
  transform: translateY(0);
  box-shadow: 0 1px 2px rgba(104, 180, 58, 0.2);
}

@media (max-width: 480px) {
  .successContainer {
    padding: 1.25rem;
    margin: 1rem auto;
  }
  
  .successIcon {
    font-size: 2.5rem;
  }
  
  .successTitle {
    font-size: 1.25rem;
  }
  
  .successMessage {
    font-size: 0.9rem;
  }
  
  .successSubMessage {
    font-size: 0.8rem;
  }
  
  .returnButton {
    padding: 0.7rem 1.4rem;
    font-size: 0.85rem;
  }
}

.spinner {
  animation: rotate 2s linear infinite;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.path {
  stroke: #ffffff;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}