.excluir-conta-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.699999988079071);
}
.excluir-conta-frame315 {
  gap: 8px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px;
  z-index: 2;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.excluir-conta-frame301 {
  gap: 32px;
  width: 100%;
  max-width: 410px;
  display: flex;
  padding: 24px;
  overflow: hidden;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.029999999329447746);
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background: white;
}
.excluir-conta-frame362 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.excluir-conta-text {
  color: #602525;
  height: auto;
  flex-grow: 1;
  text-align: left;
  line-height: 32px;
}
.excluir-conta-close {
  width: 24px;
  height: 24px;
}
.excluir-conta-frame198 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.excluir-conta-text2 {
  color: rgba(0, 12, 18, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 24px;
}
.excluir-conta-text3 {
  color: rgba(106, 108, 104, 1);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 16px;
}
.excluir-conta-frame306 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: flex-end;
}
.excluir-conta-buttons {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  border-color: rgba(192, 193, 194, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  justify-content: center;
}
.close-popup-class {
  cursor: pointer;
}
.excluir-conta-text5 {
  color: rgba(30, 33, 37, 1);
  height: auto;
  flex-grow: 1;
  text-align: center;
  line-height: 24px;
}
.excluir-conta-buttons1 {
  gap: 8px;
  display: flex;
  padding: 8px 16px;
  overflow: hidden;
  flex-grow: 1;
  align-items: center;
  border-radius: 4px;
  background-color: #e45757;
}
.excluir-conta-text7 {
  color: var(--dl-color-default-black);
  height: auto;
  flex-grow: 1;
  text-align: center;
  line-height: 24px;
}
