.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.title {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  margin-bottom: 32px;
}

.subtitle {
  font-size: 24px;
  font-weight: 600;
  color: #444;
  margin-top: 40px;
  margin-bottom: 24px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.formGroup {
  display: flex;
  flex-direction: column;
}

.formGroup label {
  font-size: 16px;
  font-weight: 500;
  color: #666;
  margin-bottom: 8px;
}

.formGroup input,
.formGroup select {
  padding: 12px 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.formGroup input:focus,
.formGroup select:focus {
  outline: none;
  border-color: #68b43a;
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.1);
}

.questionList {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.questionCard {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.questionCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.questionCard.dragging {
  opacity: 0.6;
  transform: scale(1.05);
}

.questionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  cursor: move;
}

.questionTitle {
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.removeBtn {
  background-color: transparent;
  border: none;
  color: #dc3545;
  font-size: 28px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.removeBtn:hover {
  color: #bd2130;
  transform: scale(1.1);
}

.questionInput {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  margin-bottom: 24px;
  transition: all 0.3s ease;
}

.questionInput:focus {
  outline: none;
  border-color: #68b43a;
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.1);
}

.imageUpload {
  margin-bottom: 24px;
}

.imageLabel {
  display: inline-block;
  padding: 10px 20px;
  background-color: #68b43a;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.imageLabel:hover {
  background-color: #5a9d32;
}

.imageInput {
  display: none;
}

.imagePreviewContainer {
  max-width: 100%;
  max-height: 300px;
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.imagePreview {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.alternativesTitle {
  font-size: 18px;
  font-weight: 600;
  color: #555;
  margin-bottom: 16px;
}

.alternativeItem {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.alternativeInput {
  flex-grow: 1;
  padding: 10px 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  margin-right: 16px;
  transition: all 0.3s ease;
}

.alternativeInput:focus {
  outline: none;
  border-color: #68b43a;
  box-shadow: 0 0 0 3px rgba(104, 180, 58, 0.1);
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkboxInput {
  display: none;
}

.checkboxCustom {
  width: 24px;
  height: 24px;
  border: 2px solid #68b43a;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
  transition: all 0.3s ease;
}

.checkboxCustom::after {
  content: '\2714';
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.2s ease;
}

.checkboxInput:checked + .checkboxCustom {
  background-color: #68b43a;
}

.checkboxInput:checked + .checkboxCustom::after {
  transform: translate(-50%, -50%) scale(1);
}

.addQuestionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  background-color: #f8f9fa;
  border: 2px dashed #68b43a;
  border-radius: 12px;
  color: #68b43a;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 32px;
}

.addQuestionBtn:hover {
  background-color: #e8f5e9;
}

.addIcon {
  font-size: 28px;
  margin-right: 8px;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 40px;
}

.cancelBtn,
.saveBtn {
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancelBtn {
  background-color: #fff;
  border: 2px solid #ced4da;
  color: #6c757d;
}

.cancelBtn:hover {
  background-color: #f8f9fa;
  border-color: #6c757d;
  color: #495057;
}

.saveBtn {
  background-color: #68b43a;
  border: none;
  color: #fff;
}

.saveBtn:hover {
  background-color: #5a9d32;
}

@media (max-width: 768px) {
  .modal {
    padding: 24px;
    width: 95%;
  }

  .grid {
    grid-template-columns: 1fr;
  }

  .buttonGroup {
    flex-direction: column-reverse;
  }

  .cancelBtn,
  .saveBtn {
    width: 100%;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.modal {
  animation: fadeIn 0.3s ease, slideUp 0.3s ease;
}

.questionCard {
  animation: slideUp 0.3s ease;
}